import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, DatePicker, InputGroupText,
    CardHeader, CardBody, FormSelect,label, InputGroupAddon,
    ButtonToolbar,ButtonGroup,InputGroup,Modal, ModalBody, ModalHeader,
    Button,FormInput } from "shards-react";

// import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";


import { MDBDataTable,
        MDBDataTableV5 ,
       } from 'mdbreact';

// import { MDBDatePickerV5 } from 'mdb-ui-kit';
 import moment from 'moment';


import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import PageTitle from "../../components/common/PageTitle"; 
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params"; 
import { Redirect  } from "react-router-dom";
import { FaSistrix, FaSyncAlt } from "react-icons/fa";


const  ListSAV  = (props) => {

  // let history = useHistory();
  // const  Entete =  global.token  
  const LigneSuiviClassic  =   Store2.session("LigneSuiviClassic")
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 


  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];


const [Open,setOpen] = useState( false )

/////////// FIN EXCEL EXPORT DATA

  const [Content, setContent] = useState()
  // const [datatable, setDatatable] = useState({columns:[], rows:[] })
  //Store2.session("ligne", []); 
  const [datatable, setDatatable] = useState()
  const [MaSelection, setMaSelection] = useState()
  const [Region, setRegion] = useState('')
  const [DateDebut, setDateDebut] = useState()
  const [DateFin, setDateFin] = useState()
  const [Motif, setMotif] = useState('')
  const [MotifAppel,setMotifAppel] = useState('')

  //const LigneClick = "GANSBEOGO"
  const [TestDonnes,setTestDonnes] = useState(0)

useEffect(() => {
  loadData();
  MesDonnees( Store2.session("LigneSuiviClassic") )

}, []);



// http://192.168.223.1:4200/api/region/?token=
//	axios.get( Params.Api + '/api/region/?token=' + Token )
  

const loadData = async () => {
  const Token = Store2.session("token" )
  const response = await fetch( Params.Api + '/api/dsh_SuiviClassic/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("LigneSuiviClassic", contentData); 

 // MesDonnees(contentData)
}



const MesDonnees =  (data) => {
  setDatatable()
  setDatatable({
    columns: [   
      {
        label: 'Id',
        field: 'idsuiviouvr',
        width: 10,
        sort: 'asc',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'id',
        },
      },
      {
        label: 'Plan_code_ouvrage',
        field: 'plancode',
        width: 50,
      },
      {
        label: 'Type_ouvrage',
        field: 'typeouvrage',
       // sort: 'asc',
        width: 50,
      },
      {
        label: 'Nom_Client',
        field: 'Nomprenomclient',
       // sort: 'asc',
        width: 150,
      },
      {
        label: 'Nom_Ecb',
        field: 'nomecb',
       // sort: 'asc',
        width: 100,
      },
      {
        label: 'Region',
        field: 'region',
       // sort: 'asc',
        width: 50,
      },
      {
        label: 'Province',
        field: 'province',
       // sort: 'asc',
        width: 50,
      },
      {
        label: 'Commune',
        field: 'commune',
       // sort: 'asc',         
        width: 50,
      },
      {
        label: 'Village',
        field: 'village',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Date_Suivi',
        field: 'datesuivi',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Observation_Etat',
        field: 'observation_etat',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Etat_Ouvrage',
        field: 'etatouvrage',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Etat_Lampe',
        field: 'etatlampe',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Usage_Lampe',
        field: 'usagelampe',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Etat_Foyer',
        field: 'etatfoyer',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Usage_Foyer',
        field: 'usagefoyer',
        //sort: 'asc',
        width: 50,
      },

      {
        label: 'Pers_Contactee',
        field: 'perscontactee',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Nom_Contact',
        field: 'nomContact',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Intervention',
        field: 'isIntervention',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'dateintervention',
        field: 'dateintervention',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'daterdv',
        field: 'daterdv',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'periodchargement',
        field: 'periodchargement',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'gazsuffisant',
        field: 'gazsuffisant',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'prodcompost',
        field: 'prodcompost',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'qtecompostprod',
        field: 'qtecompostprod',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'disponibilitekitclient',
        field: 'disponibilitekitclient',
        //sort: 'asc',
        width: 50,
      },
      // {
      //   label: 'Observation',
      //   field: 'observ_ouvrage',
      //   //sort: 'asc',
      //   width: 50,
      // },
  
    ],
    rows: data,
    
  })
}


const loadDataParam = async () => {


  const MesParams = {
    Region :  Region,
    //Province :  Province,
    DateDebut : DateDebut,
    DateFin :  DateFin,
    Motif : MotifAppel,

  }

  //  alert(JSON.stringify( MesParams ))

  const Token = Store2.session("token" )
  const Lien = Params.Api +  "/api/dsh_SuiviClassicparams/?token=" + Token


  const DateD  =  moment(DateDebut).format("YYYY-MM-DD")
  const DateF  =  moment(DateFin).format("YYYY-MM-DD")
 const URL = ""

//   if(  MotifAppel ==="" ){
//     alert( "Selectionnez au moins le motif SVP")
//    ///////////URL = `http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF
// return
//   }


try {
  
  const contentData = await axios.get( Params.Api +  `/api/dsh_SuiviClassicparams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF)



  setContent( contentData.data )
  Store2.session("LigneSuiviClassic", contentData.data); 
  //setDatatable()
  MesDonnees(contentData.data)


} catch (error) {
  alert("Verifier votre connexion SVP.");
}




}


const Raffraichir = async () => {

  //loadData();

  const Token = Store2.session("token" )
  const response = await fetch( Params.Api +  '/api/dsh_SuiviClassic/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("LigneSuiviClassic", contentData); 


  MesDonnees(contentData )

}



const Supprimer = async () => {

  
  if (   TypeConnexion === "Utilisateur"){
    alert(Params.infoDroitInsuffisant)  
    return
  }

  

  const Token = Store2.session("token" )

//  Test de confirmation de selection
  if( MaSelection ){
        
          let Reponnse = window.confirm("Êtes-vous sûr de vouloir supprimer cette ligne ?");
// Test de confirmation de suppression
          if (Reponnse ) {
        
                    try {
                      const contentData = await axios.put( Params.Api +  `/api/dsh_suiviclassic_sup/?token=` + Token + `&id=` + checkbox1.idsuiviouvr )
                    
                      const Res = JSON.stringify(contentData.data.Error)

                  //  alert ( Res  )
                    
                      if (Res  === true ){
                        alert("Suppression impossible conctacter Admin")
                        
                      
                      }else{

                        alert("Suppession terminée avec succès")
                      }
                  
                      Raffraichir()
        
                    
                    } catch (error) {
                      alert("Verifier votre connexion SVP.");
                    }
                    
          } else {
            // Do nothing!
        
          }
  
    



    
  }else{
    alert("Selctionner un élément dans la liste SVP")
  }



  



}



const Modifier = () => {

//	e.preventDefault();
//const  history = useHistory();
// alert( 'ID = ' + MaSelection)

if( MaSelection ){
  props.history.push("/Edit_Suivi_Classic")
}else{
  alert("Selctionner un élément dans la liste")
}



}


const Nouveau = () => {
  //	e.preventDefault();
  //const  history = useHistory();
  // alert( 'ID = ' + MaSelection)

  Store2.session("ligneSelectSuiviClassic", "")
  props.history.push("/Edit_Suivi_Classic")


}

const [checkbox1, setCheckbox1] = useState('');
const showLogs2 = (e) => {
  setCheckbox1(e);
  setMaSelection(e.idsuiviouvr)
  Store2.session("ligneSelectSuiviClassic", e)
};

const Lignes =  Store2.session("LigneSuiviClassic")

//alert (JSON.stringify(Lignes))


const ChangeDateDebut = (value) => {
setDateDebut( new Date(value) )
}

const ChangeDateFin  = (value) => {
  setDateFin( new Date(value) )
}

 
                      
const ExportationExcel = () => {

 const Donnees =  Store2.session("LigneSuiviClassic"); 



  return (
    <ExcelFile element={
    <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
    Exporter
    </Button>}>
                <ExcelSheet filename ="ExportMiseEnService" data={Donnees} name="SuiciClassic">
                    <ExcelColumn label="plancode" value="plancode"/>
                    <ExcelColumn label="typeouvrage" value="typeouvrage"/>
                    <ExcelColumn label="NomPrenomClient" value="NomPrenomClient"/>
                    <ExcelColumn label="NomPrenomMacon" value="NomPrenomMacon"/>
                    <ExcelColumn label="NomEcb" value="NomEcb"/>

                    <ExcelColumn label="etatouvrage" value="etatouvrage"/>
                    <ExcelColumn label="Region" value="Region"/>
                    <ExcelColumn label="Province" value="Province"/>
                    <ExcelColumn label="Commune" value="Commune"/>
                    <ExcelColumn label="Village" value="Village"/>
                    <ExcelColumn label="observation" value="observ_ouvrage"/>
                    <ExcelColumn label="daterdv" value="daterdv"/>
                    <ExcelColumn label="datesuivi" value="datesuivi"/>
                    <ExcelColumn label="etatlampe" value="etatlampe"/>
                    <ExcelColumn label="usagelampe" value="usagelampe"/>
                    <ExcelColumn label="etatfoyer" value="etatfoyer"/>
                    <ExcelColumn label="usagefoyer" value="usagefoyer"/>
                    <ExcelColumn label="nomContact" value="nomContact"/>
                    <ExcelColumn label="intervention" value="isIntervention"/>
                    <ExcelColumn label="dateintervention" value="dateintervention"/>
                    <ExcelColumn label="periodchargement" value="periodchargement"/>
                    <ExcelColumn label="gazsuffisant" value="gazsuffisant"/>
                    <ExcelColumn label="prodcompost" value="prodcompost"/>
                    <ExcelColumn label="qtecompostprod" value="qtecompostprod"/>
                    <ExcelColumn label="disponibilitekitclient" value="disponibilitekitclient"/>
                    <ExcelColumn label="observ_ouvrage" value="observ_ouvrage"/>


{/** 
 
 
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>

*/}
                </ExcelSheet>
{/** 
 
                 <ExcelSheet  filename ="ExportGarbal" data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>

*/}
            </ExcelFile>
);





}



const ExportationExcelParams = () => {

  const Donnees =  Store2.session("LigneSuiviClassic"); 
 
 /**
  
  if modal non activer
  return
 else
 
  */
 
   return (
     <ExcelFile element={
     <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
     Exporter
     </Button>}>
                 <ExcelSheet filename ="ExportGarbal" data={Donnees} name="Employees">
                     <ExcelColumn label="date_appel" value="date_appel"/>
                     <ExcelColumn label="nom_appelant" value="nom_appelant"/>
                     <ExcelColumn label="contact_appelant" value="contact_appelant"/>
                     <ExcelColumn label="profil_appelant" value="profil_appelant"/>
                     <ExcelColumn label="region" value="region"/>
                     <ExcelColumn label="province" value="province"/>
                     <ExcelColumn label="commune" value="commune"/>
                     <ExcelColumn label="village" value="village"/>
                     <ExcelColumn label="observation" value="observation"/>

                     <ExcelColumn label="typeanimaux" value="typeanimaux"/>
                     <ExcelColumn label="nombretete" value="nombretete"/>
                     <ExcelColumn label="quantitebouse" value="quantitebouse"/>
                     <ExcelColumn label="fonctionnement" value="etat"/>
                     <ExcelColumn label="typeaccessoires" value="typeaccessoires"/>
                     <ExcelColumn label="quantite" value="quantite"/>
                     <ExcelColumn label="unite" value="unite"/>
                     <ExcelColumn label="traite" value="traite"/>


                 </ExcelSheet>

             </ExcelFile>
 );
 
 }



const ExportationExcel2 = () => {

  
const multiDataSet = [
  {
    columns: [
      { value: "Name", widthPx: 50 }, // width in pixels
      { value: "Salary", widthCh: 20 }, // width in charachters
      { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
    ],
    data: [
      ["Johnson", 30000, "Male"],
      ["Monika", 355000, "Female"],
      ["Konstantina", 20000, "Female"],
      ["John", 250000, "Male"],
      ["Josef", 450500, "Male"],
    ],
  },
  {
    xSteps: 1, // Will start putting cell with 1 empty cell on left most
    ySteps: 5, //will put space of 5 rows,
    columns: ["Name", "Department"],
    data: [
      ["Johnson", "Finance"],
      ["Monika", "IT"],
      ["Konstantina", "IT Billing"],
      ["John", "HR"],
      ["Josef", "Testing"],
    ],
  },
];


  return (
    <ExcelFile element={
      <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
      Exporter2
      </Button>}>
      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
    </ExcelFile>
);

}

const ExportModal = () => {

	if(Open){
		setOpen(false)
	}else{
		setOpen(true)
	}

	///setTraite(Checked)


  }

const ExportChoix = () => {
alert (MotifAppel)
    let Reponnse = window.confirm("Exporter avec votre choix de motif ?");
    // Test de confirmation de suppression
    if (Reponnse) {
       return( < ExportationExcelParams /> )

    }else{
      return(  < ExportationExcel /> )
    }

  }

const MaRegion =   Store2.session("Region")
const Donnees= Store2.session("LigneSuiviClassic")
const MotifGarbal =   Store2.session("MotifGarbal")
// alert ( JSON.stringify( Donnees))


// if ( datatable  ){
// if( TestDonnes === 1 ){
    
  if (typeof(datatable) != "undefined"){


  if ( Token  === undefined  ||  Token  === "" || TypeConnexion === "Partenaire"  || TypeConnexion === "Opérateur Garbal"  ) {
    //Affichage de la redirection
   // alert('  Erreur : Verifier votre Email / Mot de passe. ')
    return <Redirect to='/'/>;
  
  }
  



  const today = new Date();
  const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
  const twoDaysAgo = (d => new Date(d.setDate(d.getDate() - 2)))(new Date());



  const ModalContent = () => {
    return(
   
        <Modal  size="md" centered = {true}  backdrop = {false} open={Open} toggle={ExportModal}>
           <ModalHeader>Exportatation par motif</ModalHeader>
            < ExportationExcel/>

            <Row >
            {/* MotifAppelant */}
              <Col md="6" className="form-group">
                <label htmlFor="MotifAppelant">Motif de l'appelant </label>
                <FormSelect id="MotifAppel"
                  /* required */
                  value={Motif}
                  onChange={(e) => {
                    e.preventDefault();
                    setMotif(e.target.value)
                    // alert( "valuer = " + e.target.value)
                  }}
                >
                  <option> </option>
                  {
                    MotifGarbal.map(item => {
                      return (
                        <option value={item.id}>{item.motif} </option>
                      )
                    })
                  }
                </FormSelect>

              </Col>
              {/* DateDebut */}
              <Col md="6" className="form-group">
              <InputGroup size="sm" className="ml-auto">

{ /** VOI apres comment gerer les deux InputGroup  */}

       <InputGroup size="sm" className = "d-flex my-auto date-range">




    <InputGroupAddon type="append">

            
          <InputGroupText> 
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>

        </InputGroupAddon>


        <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
        <DatePicker
          size="sm"
          selected={DateFin}
          onChange={ChangeDateFin}
          placeholderText="Date Fin"
          dropdownMode="select"
          className="text-center"
        />

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>
					  <FormSelect id="Regions"
					  /* required */
					  value={Region} 
						onChange={(e) => {
              setRegion(e.target.value)
           }}
					  >

              {
                MaRegion.map( item => {
                  return(
                 <option>{item.nom} </option>
                  )
                })
              }
						
					  </FormSelect>


    </InputGroup>


             </InputGroup>

              </Col>
    
            </Row>

          </Modal>

   
    )
  }



  return (
    <>
       <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Suivi Classique" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>

            
            <Card small className="mb-4">
            <CardHeader className="border-bottom">
            <CardBody className="p-0 pb-3">


            <Row>   

                                <Col  md="12">

         <ButtonToolbar>

                    <ButtonGroup size="sm">

                    <ExportationExcel />
                    
 {
   /** 
    
                      <Button onClick={() => 
                    ExportModal()
                  // ExportChoix()
                     } marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Export
                      </Button>
                      < ModalContent />
                      
   */
 }
                      

                      
                      <Button onClick={() => Nouveau()} marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Nouveau
                      </Button>


                      
                      
                      <Button onClick={() => Modifier()}
                      marginRight={8}  outline theme="success" className="mb-2 mr-1">
                        Modifier
                      </Button>

                      <Button onClick={() => Supprimer()} marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Supprimer
                      </Button>

                      <Button onClick={() => Raffraichir()} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >
                       <FaSyncAlt style={{color: 'blue' , fontSize: '20px' }}/>  
                       
                      </Button>


                      <Button onClick={() => loadDataParam()} marginRight={8} 
                      outline theme="dark" className="mb-2 mr-1" >
                         <FaSistrix style={{color: 'red' , fontSize: '20px' }}/>  
                      </Button>

                    </ButtonGroup>

      <InputGroup size="sm" className="ml-auto">

{ /** VOI apres comment gerer les deux InputGroup  */}

       <InputGroup size="sm" className = "d-flex my-auto date-range">


    <InputGroupAddon type="append">

            
          <InputGroupText> 
          <FaSistrix style={{color: 'black' , fontSize: '15px' }}/> 
          </InputGroupText>

        </InputGroupAddon>


        <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
        
        <DatePicker
          size="sm"
          selected={DateFin}
          onChange={ChangeDateFin}
          placeholderText="Date Fin"
          dropdownMode="select"
          className="text-center"
        />

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>


    </InputGroup>


      </InputGroup>


    </ButtonToolbar>




     <div>
        <br/>
      </div>

         {/**
          *
          * <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-warning" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>


          */}

          </Col>
                
  

       </Row>




                <MDBDataTableV5  responsive autoWidth striped
                    hover
                    entriesOptions={[10, 20, 30, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    small
                    bordered
                    striped
                   // scrollX
                   //scrollY
                    
                    
                   exportToCSV proSelect 
                    fullPagination 
                    filter='office'  
                    searchTop searchBottom={false}

                    data={datatable}
                    checkbox
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                    showLogs2(e);
                    }}

                />


            </CardBody>
            </CardHeader>
            </Card>

   




 {
     //'oooooooooooooooooooooAAA \n' + JSON.stringify(checkbox1).name
     Store2.session("LigneSelect", checkbox1.idsuiviouvr) 
 }




  </Container>

    </>
  );

}else{

  return(
    <>
           <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Suivi classique" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>




    <div> CHARGEMENT EN COURS ...  </div>
    </Container>
    
    </>
  )



}

}

export default ListSAV;