import { useEffect, useState } from "react";
import axios from "axios";
import Store2 from "store2"; 
import Params from "./Params"; 

const  useFetch =  (url,urlsearch, Val, urlfilter, stockage)  => {
  const [data, setData] = useState([]);
  const [ListeDonnees, setListeDonnees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const [filteredData, setFilteredData] = useState([]);
  


const DateD =  Store2.session("DateD" ); 
 const DateF = Store2.session("DateF" ); 

  const Url2 =  urlsearch   + `&DateDebut=` + DateD + `&DateFin=` + DateF
  const Url3 =  urlfilter 
  
// alert( Url3 )
 
  useEffect(  () => {

    setLoading(true);
   // alert( url )
    axios({
      url: url,
      method: 'GET',
      responseType: 'stream' // important
    }).then((response) => {
        setData(response.data);

        setListeDonnees({
            columns: [   
              {
                label: 'Id',
                field: 'idouvrage',
                width: 10,
                sort: 'asc',
                attributes: {
                  'aria-controls': 'DataTable',
                  'aria-label': 'id',
                },
              },
              {
                label: 'Plan_code_ouvrage',
                field: 'plancode',
                width: 100,
              },
              {
                label: 'Type_ouvrage',
                field: 'typeouvrage',
              // sort: 'asc',
                width: 50,
              },
              {
                label: 'Nom_Client',
                field: 'NomPrenomClient',
              // sort: 'asc',
                width: 150,
              },
              {
                label: 'Nom_Ecb',
                field: 'NomEcb',
              // sort: 'asc',
                width: 100,
              },
              {
                label: 'Region',
                field: 'Region',
              // sort: 'asc',
                width: 50,
              },
              {
                label: 'Province',
                field: 'Province',
              // sort: 'asc',
                width: 50,
              },
              {
                label: 'Commune',
                field: 'Commune',
              // sort: 'asc',         
                width: 50,
              },
              {
                label: 'Village',
                field: 'Village',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'Etat',
                field: 'etatouvrage',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'Obs_etat',
                field: 'observation_etat',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'datefintravaux',
                field: 'datefintravaux',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'datecontrolequalite',
                field: 'datecontrolequalite',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'datereceptprov',
                field: 'datereceptprov',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'datemiseservice',
                field: 'datemiseservice',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'datereceptdef',
                field: 'datereceptdef',
                //sort: 'asc',
                width: 50,
              },
          
              {
                label: 'connection_latrine',
                field: 'cnxlat',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'etalat',
                field: 'etalat',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'hangarexiste',
                field: 'hangarexiste',
                //sort: 'asc',
                width: 50,
              },
              {
                label: 'certificatexiste',
                field: 'certificatexiste',
                //sort: 'asc',
                width: 50,
              },
          
            ],
            rows: response.data,
            
          })

          Store2.session( stockage, response.data); 
          
     // alert(response.data);
      })   
      .catch((err) => {
        setError(err);
      // alert( err )
      })
      .finally(() => {
        setLoading(false);
      });

     
  }, [url]);



  const refetch = () => {
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setData(response.data);

        setListeDonnees({
          columns: [   
            {
              label: 'Id',
              field: 'idouvrage',
              width: 10,
              sort: 'asc',
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'id',
              },
            },
            {
              label: 'Plan_code_ouvrage',
              field: 'plancode',
              width: 100,
            },
            {
              label: 'Type_ouvrage',
              field: 'typeouvrage',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Nom_Client',
              field: 'NomPrenomClient',
            // sort: 'asc',
              width: 150,
            },
            {
              label: 'Nom_Ecb',
              field: 'NomEcb',
            // sort: 'asc',
              width: 100,
            },
            {
              label: 'Region',
              field: 'Region',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Province',
              field: 'Province',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Commune',
              field: 'Commune',
            // sort: 'asc',         
              width: 50,
            },
            {
              label: 'Village',
              field: 'Village',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Etat',
              field: 'etatouvrage',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Obs_etat',
              field: 'observation_etat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datefintravaux',
              field: 'datefintravaux',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datecontrolequalite',
              field: 'datecontrolequalite',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptprov',
              field: 'datereceptprov',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datemiseservice',
              field: 'datemiseservice',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptdef',
              field: 'datereceptdef',
              //sort: 'asc',
              width: 50,
            },
        
            {
              label: 'connection_latrine',
              field: 'cnxlat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'etalat',
              field: 'etalat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'hangarexiste',
              field: 'hangarexiste',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'certificatexiste',
              field: 'certificatexiste',
              //sort: 'asc',
              width: 50,
            },
        
          ],
          rows: response.data,
          
        })

        Store2.session( stockage, response.data); 

      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const recherche = () => {
    // alert( Url2 )

    console.log( Url2 )
    setLoading(true);
    axios
      .get(Url2)
      .then((response) => {
        setData(response.data);

        setListeDonnees({
          columns: [   
            {
              label: 'Id',
              field: 'idouvrage',
              width: 10,
              sort: 'asc',
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'id',
              },
            },
            {
              label: 'Plan_code_ouvrage',
              field: 'plancode',
              width: 100,
            },
            {
              label: 'Type_ouvrage',
              field: 'typeouvrage',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Nom_Client',
              field: 'NomPrenomClient',
            // sort: 'asc',
              width: 150,
            },
            {
              label: 'Nom_Ecb',
              field: 'NomEcb',
            // sort: 'asc',
              width: 100,
            },
            {
              label: 'Region',
              field: 'Region',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Province',
              field: 'Province',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Commune',
              field: 'Commune',
            // sort: 'asc',         
              width: 50,
            },
            {
              label: 'Village',
              field: 'Village',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Etat',
              field: 'etatouvrage',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Obs_etat',
              field: 'observation_etat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datefintravaux',
              field: 'datefintravaux',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datecontrolequalite',
              field: 'datecontrolequalite',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptprov',
              field: 'datereceptprov',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datemiseservice',
              field: 'datemiseservice',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptdef',
              field: 'datereceptdef',
              //sort: 'asc',
              width: 50,
            },
        
            {
              label: 'connection_latrine',
              field: 'cnxlat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'etalat',
              field: 'etalat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'hangarexiste',
              field: 'hangarexiste',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'certificatexiste',
              field: 'certificatexiste',
              //sort: 'asc',
              width: 50,
            },
        
          ],
          rows: response.data,
          
        })

        Store2.session( stockage, response.data); 
        
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });


  };


  const filtrage = () => {
    setLoading(true);
    axios
      .get(Url3)
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
       // alert(response.data);

        setListeDonnees({
          columns: [   
            {
              label: 'Id',
              field: 'idouvrage',
              width: 10,
              sort: 'asc',
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'id',
              },
            },
            {
              label: 'Plan_code_ouvrage',
              field: 'plancode',
              width: 100,
            },
            {
              label: 'Type_ouvrage',
              field: 'typeouvrage',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Nom_Client',
              field: 'NomPrenomClient',
            // sort: 'asc',
              width: 150,
            },
            {
              label: 'Nom_Ecb',
              field: 'NomEcb',
            // sort: 'asc',
              width: 100,
            },
            {
              label: 'Region',
              field: 'Region',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Province',
              field: 'Province',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Commune',
              field: 'Commune',
            // sort: 'asc',         
              width: 50,
            },
            {
              label: 'Village',
              field: 'Village',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Etat',
              field: 'etatouvrage',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Obs_etat',
              field: 'observation_etat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datefintravaux',
              field: 'datefintravaux',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datecontrolequalite',
              field: 'datecontrolequalite',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptprov',
              field: 'datereceptprov',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datemiseservice',
              field: 'datemiseservice',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptdef',
              field: 'datereceptdef',
              //sort: 'asc',
              width: 50,
            },
        
            {
              label: 'connection_latrine',
              field: 'cnxlat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'etalat',
              field: 'etalat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'hangarexiste',
              field: 'hangarexiste',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'certificatexiste',
              field: 'certificatexiste',
              //sort: 'asc',
              width: 50,
            },
        
          ],
          rows: response.data,
          
        })

        Store2.session( stockage, response.data); 
        
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const searchFilter = () => {
   // refetch()       
   // alert( Val )
    if(Val){  
        const newData = data.filter(item => {
            const itemData = item.plancode ? item.plancode.toUpperCase() : ''.toUpperCase();
            const textData = Val.toUpperCase();
            return itemData.indexOf(textData) > -1;
        })
        setFilteredData(newData);

        setListeDonnees({
          columns: [   
            {
              label: 'Id',
              field: 'idouvrage',
              width: 10,
              sort: 'asc',
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'id',
              },
            },
            {
              label: 'Plan_code_ouvrage',
              field: 'plancode',
              width: 100,
            },
            {
              label: 'Type_ouvrage',
              field: 'typeouvrage',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Nom_Client',
              field: 'NomPrenomClient',
            // sort: 'asc',
              width: 150,
            },
            {
              label: 'Nom_Ecb',
              field: 'NomEcb',
            // sort: 'asc',
              width: 100,
            },
            {
              label: 'Region',
              field: 'Region',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Province',
              field: 'Province',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Commune',
              field: 'Commune',
            // sort: 'asc',         
              width: 50,
            },
            {
              label: 'Village',
              field: 'Village',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Etat',
              field: 'etatouvrage',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Obs_etat',
              field: 'observation_etat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datefintravaux',
              field: 'datefintravaux',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datecontrolequalite',
              field: 'datecontrolequalite',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptprov',
              field: 'datereceptprov',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datemiseservice',
              field: 'datemiseservice',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptdef',
              field: 'datereceptdef',
              //sort: 'asc',
              width: 50,
            },
        
            {
              label: 'connection_latrine',
              field: 'cnxlat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'etalat',
              field: 'etalat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'hangarexiste',
              field: 'hangarexiste',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'certificatexiste',
              field: 'certificatexiste',
              //sort: 'asc',
              width: 50,
            },
        
          ],
          rows: newData,
          
        })

        Store2.session( stockage, newData); 
        


    } else {
        setFilteredData(data);

        setListeDonnees({
          columns: [   
            {
              label: 'Id',
              field: 'idouvrage',
              width: 10,
              sort: 'asc',
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'id',
              },
            },
            {
              label: 'Plan_code_ouvrage',
              field: 'plancode',
              width: 100,
            },
            {
              label: 'Type_ouvrage',
              field: 'typeouvrage',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Nom_Client',
              field: 'NomPrenomClient',
            // sort: 'asc',
              width: 150,
            },
            {
              label: 'Nom_Ecb',
              field: 'NomEcb',
            // sort: 'asc',
              width: 100,
            },
            {
              label: 'Region',
              field: 'Region',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Province',
              field: 'Province',
            // sort: 'asc',
              width: 50,
            },
            {
              label: 'Commune',
              field: 'Commune',
            // sort: 'asc',         
              width: 50,
            },
            {
              label: 'Village',
              field: 'Village',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Etat',
              field: 'etatouvrage',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'Obs_etat',
              field: 'observation_etat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datefintravaux',
              field: 'datefintravaux',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datecontrolequalite',
              field: 'datecontrolequalite',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptprov',
              field: 'datereceptprov',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datemiseservice',
              field: 'datemiseservice',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'datereceptdef',
              field: 'datereceptdef',
              //sort: 'asc',
              width: 50,
            },
        
            {
              label: 'connection_latrine',
              field: 'cnxlat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'etalat',
              field: 'etalat',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'hangarexiste',
              field: 'hangarexiste',
              //sort: 'asc',
              width: 50,
            },
            {
              label: 'certificatexiste',
              field: 'certificatexiste',
              //sort: 'asc',
              width: 50,
            },
        
          ],
          rows: data,
          
        })

        Store2.session( stockage, data); 

    }
}


  

  return { data, ListeDonnees, loading, error, refetch, recherche, filtrage,searchFilter  };
}

export default useFetch;
