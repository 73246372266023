import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

import Store2 from "store2"; 
import { FaUserCircle } from "react-icons/fa";


export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  
  Deconnexion() {
    Store2.session("token", "" )
    Store2.session("MaRedirection", "" )
    Store2.session("TypeConnexion", "" )
  }



  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
           //alt="User Avatar"
          />{" "} */}

          <FaUserCircle style={{color: 'blue' , fontSize: '40px' }}/> {" "}

          <span className="d-none d-md-inline-block"> {  Store2.session("EmailUser" ) }  </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
         {
           /**
             <DropdownItem tag={Link} to="#">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
            */
         }
          <DropdownItem tag={Link} to="#">
            <i className="material-icons">&#xE8B8;</i> Editer le profile
          </DropdownItem>

         { /**
           <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Fichiers
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Autres
          </DropdownItem>
          <DropdownItem divider />

          */}
          <DropdownItem tag={Link} to="/" className="text-danger"
                 onClick={this.Deconnexion}>>
            <i className="material-icons text-danger">&#xE879;</i> Déconnexon
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
