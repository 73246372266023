import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, DatePicker, InputGroupText,
    CardHeader, CardBody, FormSelect,label, InputGroupAddon,
    ButtonToolbar,ButtonGroup,InputGroup,Modal, ModalBody, ModalHeader,
    Button,FormInput } from "shards-react";

// import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";


import { MDBDataTable,
        MDBDataTableV5 ,
       } from 'mdbreact';

// import { MDBDatePickerV5 } from 'mdb-ui-kit';
 import moment from 'moment';
   

import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import PageTitle from "../../components/common/PageTitle"; 
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params";   
import useFetch from "../../Pages/Config/useFetch"; 
import { Redirect  } from "react-router-dom";
import { FaSistrix, FaSyncAlt, FaRegMap } from "react-icons/fa";
import MonLOg  from "log-to-file"

import { Table, Pagination, Input, Stack  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; // or 'rsuite/dist/rsuite.min.css'
import Ouvrage_vide from "./Ouvrage_vide.json"; 

// import { Elasticsearch, Results, SearchBox } from "react-elasticsearch";
// import { ReactiveBase, DataSearch, SingleRange } from "@appbaseio/reactivesearch";


  
const  ListActiveOuvrahe  = (props) => {

  // let history = useHistory();
  // const  Entete =  global.token  

  ///  const [Loading, setLoading] = useState(true)

  const ligneAttente  =   Store2.session("ligneAttente")
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 
  const RegionSelect = Store2.session("RegionVal" )




// alert (  Params.Api + '/api/dsh_ReceptProv/?token=' + Token + '&region=' + RegionSelect   )
 


  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const [Open,setOpen] = useState( false )

/////////// FIN EXCEL EXPORT DATA

  const [Content, setContent] = useState([])
  // const [datatable, setDatatable] = useState({columns:[], rows:[] })
  //Store2.session("ligne", []); 
  const [datatable, setDatatable] = useState()
  const [MaSelection, setMaSelection] = useState()
  const [Region, setRegion] = useState('')
  const [DateDebut, setDateDebut] = useState()
  const [DateFin, setDateFin] = useState()
  const [Motif, setMotif] = useState('')
  const [MotifAppel,setMotifAppel] = useState('')

  const [Recherche,setRecherche] = useState('')
 

  const [MesOuvrages, setMesOuvrages] = React.useState(Ouvrage_vide);

  const [Chargement, setChargement] = useState(false);
const [MonFetch, setMonFetch] = useState([]);

const [Monerror, setMonerror] = useState(null);




const [geojsonData, setGeojsonData] = useState(null);


// const [data, setData] = useState([]);
const [EnCharge, setEnCharge] = useState(false);





const ListeRegion = [
  {
    "idregion": 1,
    "nom": "BOUCLE DU MOUHOUN",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 2,
    "nom": "CASCADES",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 3,
    "nom": "CENTRE",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 4,
    "nom": "CENTRE-EST",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 5,
    "nom": "CENTRE-NORD",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 6,
    "nom": "CENTRE-OUEST",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 7,
    "nom": "CENTRE-SUD",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 8,
    "nom": "EST",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 10,
    "nom": "HAUTS-BASSINS",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 13,
    "nom": "NORD",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 11,
    "nom": "PLATEAU CENTRAL",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 12,
    "nom": "SAHEL",
    "passe": "12345678",
    "synchronize": 0
  },
  {
    "idregion": 9,
    "nom": "SUD-OUEST",
    "passe": "12345678",
    "synchronize": 0
  }
]

 


useEffect(() => {


 fetchData()
//  alert( "oooooooooooooo")

}, []);




const fetchData = async () => {
setChargement(true);
setMonerror(null);

try {
  const response = await axios.get( Params.Api + '/api/dsh_ReceptProvActive/?token=' + Token + '&region=' + RegionSelect );
  setMonFetch(response.data);
  setMesOuvrages(response.data ) 
  // alert( response.data.length)
} catch (error) {
  setMonerror(error.message);
}

setChargement(false);
};



const RechercheDate = async () => {


setChargement(true);
setMonerror(null);

try {
  const response = await axios.get( Params.Api + '/api/dsh_ReceptProvActive/?token=' + Token + '&region=' + RegionSelect );
 
  const jsonData = await response.data;

  // const startDate =  '2023-03-01'
  // const endDate = '2023-03-10'

    const startDate = new Date ( moment(DateDebut).format("YYYY-MM-DD") ) ;
    const endDate =   new Date (moment(DateFin).format("YYYY-MM-DD"));

 // Exclure les dates null
 // const nonNullDates = jsonData.filter(item => item.datereceptprov !== null);

 const nonNullDates = jsonData.filter(item => {
  const date = new Date(item.datereceptprov);
  // return item.datereceptprov !== null && date >= new Date('2023-03-1') && date <= new Date('2023-03-11');
 
  return item.datereceptprov !== null && date >= startDate && date <= endDate ;
  // return item.datefintravaux !== null && date >= startDate && date <= endDate ;

});


  // const filteredData = nonNullDates.filter(item => {
  //   // alert ( item.datereceptprov )
  //   const itemDate = new Date(item.datereceptprov);
  //   return itemDate >= startDate && itemDate <= endDate
  // });

  // alert( nonNullDates)
  setMonFetch( nonNullDates);
  setMesOuvrages( nonNullDates ) 
// alert ( filteredData )

  // // Filtre sur une plage de date sur un champ date
  // const filteredData = jsonData.filter(item => {
  //   // Remplacer 'dateField' par le nom du champ date dans votre JSON
  //   const date = new Date(item.datereceptprov);
  //   // const startDate = moment(DateDebut).format("YYYY-MM-DD") ;
  //   // const endDate = moment(DateFin).format("YYYY-MM-DD") ;


  //   return date >= startDate && date <= endDate;
  //   // return date >= startDate && date <= endDate;
  // });

  // setMonFetch( filteredData);
  // setMesOuvrages( filteredData ) 
  /////////////// alert( response.data.length)
} catch (error) {
  setMonerror(error.message);
  alert( error)
}

setChargement(false);
};


const Rafraichissement = async () => {
setChargement(true);
setMonerror(null);

try {
  const response = await axios.get( Params.Api + '/api/dsh_ReceptProvActive/?token=' + Token + '&region=' + RegionSelect );
  setMonFetch(response.data);
  setMesOuvrages(response.data ) 
  // alert( response.data.length)
} catch (error) {
  setMonerror(error.message);
}

setChargement(false);
};



const RechercheSaisie = async ( saisie ) => {

setChargement(true);
setMonerror(null);

const filterJSON = (json, inputValue) => {
  return json.filter((item) => {
    return item.plancode.toLowerCase().includes(inputValue.trim().toLowerCase());
  });
};

try {
  const response = await axios.get( Params.Api + '/api/dsh_ReceptProvActive/?token=' + Token + '&region=' + RegionSelect );
 
  const jsonData = await response.data;

 // const nonNullDates = jsonData.filter(item => item.plancode.trim().toLowerCase()  === saisie.trim().toLowerCase() );

  
 const nonNullDates = filterJSON(jsonData, saisie);


  setMonFetch( nonNullDates);
  setMesOuvrages( nonNullDates ) 

} catch (error) {
  setMonerror(error.message);
  alert( error)
}

setChargement(false);
};



const AfficheMap = () => {
// Récupérer le fichier GeoJSON depuis une source externe
  // alert(Params.Api + '/geojson')
fetch(Params.Api + '/geojson')
  .then(response => response.json())
  .then(data => {
    setGeojsonData(data);
    openMapInNewTab();
  })
  .catch(error => {
    console.error('Erreur lors de la récupération du fichier GeoJSON :', error);
  });
};




const openMapInNewTab = () => {

// const htmlContent = '<!DOCTYPE html><html><head><title>BioSynchro - Carte des biodigesteur du Burkina Faso</title><meta charset="utf-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0"><link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" /><style>body { margin: 0; padding: 0; } #map { width: 100%; height: 100vh; }</style></head><body><div id="map"></div><script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script><script>var map = L.map("map").setView([12.2383, -1.5616], 7); L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors', maxZoom: 18, }).addTo(map); fetch('http://localhost:4001/geojson').then(function(response) { return response.json(); }).then(function(data) { L.geoJSON(data, { onEachFeature: function (feature, layer) { if (feature.properties && feature.properties.plancode && feature.properties.region && feature.properties.plancode ) { layer.bindPopup(` <b>Plancode: ${feature.properties.plancode}</b> `); } } }).addTo(map); });</script></body></html>';
// Pour teste local
// const htmlContent = '<!DOCTYPE html><html><head><title>BioSynchro - Carte des biodigesteur du Burkina Faso</title><meta charset="utf-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0"><link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" /><style>body { margin: 0; padding: 0; } #map { width: 100%; height: 100vh; }</style></head><body><div id="map"></div><script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script><script>var map = L.map("map").setView([12.2383, -1.5616], 7); L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: "Map data &copy; <a href=\\"https://www.openstreetmap.org/\\">OpenStreetMap</a> contributors", maxZoom: 18, }).addTo(map); fetch("http://localhost:4001/geojson").then(function(response) { return response.json(); }).then(function(data) { L.geoJSON(data, { onEachFeature: function (feature, layer) { if (feature.properties && feature.properties.plancode && feature.properties.region && feature.properties.plancode ) { layer.bindPopup(` <b>Plancode:</b> ${feature.properties.plancode} <br><b>Nom:</b> ${feature.properties.nomclient} <br><b>Prénom(s):</b> ${feature.properties.prenomclient} <br><b>Téléphone:</b> ${feature.properties.tel1client} <br><b>Region:</b> ${feature.properties.region} <br><b>Province:</b> ${feature.properties.province} <br><b>Commune:</b> ${feature.properties.commune} <br><b>Village:</b> ${feature.properties.village} <br> `); } } }).addTo(map); });</script></body></html>';

// Pour Teste PROD
const htmlContent = '<!DOCTYPE html><html><head><title>BioSynchro - Carte des biodigesteur du Mali Faso</title><meta charset="utf-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0"><link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" /><style>body { margin: 0; padding: 0; } #map { width: 100%; height: 100vh; }</style></head><body><div id="map"></div><script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script><script>var map = L.map("map").setView([12.2383, -1.5616], 7); L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: "Map data &copy; <a href=\\"https://www.openstreetmap.org/\\">OpenStreetMap</a> contributors", maxZoom: 18, }).addTo(map); fetch("http://5.183.8.94:4400/geojson").then(function(response) { return response.json(); }).then(function(data) { L.geoJSON(data, { onEachFeature: function (feature, layer) { if (feature.properties && feature.properties.plancode && feature.properties.region && feature.properties.plancode ) { layer.bindPopup(` <b>Plancode:</b> ${feature.properties.plancode} <br><b>Nom:</b> ${feature.properties.nomclient} <br><b>Prénom(s):</b> ${feature.properties.prenomclient} <br><b>Téléphone:</b> ${feature.properties.tel1client} <br><b>Region:</b> ${feature.properties.region} <br><b>Province:</b> ${feature.properties.province} <br><b>Commune:</b> ${feature.properties.commune} <br><b>Village:</b> ${feature.properties.village} <br> `); } } }).addTo(map); });</script></body></html>';



// layer.bindPopup(`<b>Plancode:</b> ${feature.properties.plancode}<br>
// <b>nomclient:</b> ${feature.properties.nomclient} <br>
//  <b>prenomclient:</b> ${feature.properties.prenomclient} <br>
//    <b>tel1client:</b> ${feature.properties.tel1client} <br>
   
// <b>Region:</b> ${feature.properties.region} <br>
//  <b>Province:</b> ${feature.properties.province} <br>
//  <b>Commune:</b> ${feature.properties.commune} <br>
//  <b>Village:</b> ${feature.properties.comvillagemune} <br>
 
 
// `);

const newTab = window.open();
newTab.document.write(htmlContent);
newTab.document.close();
};






const Tableau = () => {
  //   const MesOuvrages =  Store2.session("ligneAttente") === null  ? Ouvrage_vide : Store2.session("ligneAttente")
  
  const [loading, setLoading] = React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  //  const [MesOuvrages, setMesOuvrages] = React.useState(Ouvrage_vide);

//    alert( JSON.stringify( MesOuvrages))
  // const MesOuvrages = JSON.stringify( Store2.session("ligneAttente") )
  //   const MesOuvrages = Store2.session("ligneAttente")
  // setTimeout(() => {
  //   setMesOuvrages(  Store2.session("ligneAttente") ) 
  // }, 3000);
  

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };               
   

  if (!MesOuvrages.length > 0) {
    setMesOuvrages( Ouvrage_vide ) 
  }

  // if ( datatable  ){
// if( TestDonnes === 1 ){
    
  // if (typeof(MesOuvrages) != "undefined"){
   // alert( MesOuvrages.length )

 const datas = (typeof(MesOuvrages.length) != "undefined") ? MesOuvrages.filter((v, i) => {
  const start = limit * (page - 1);
  const end = start + limit;
  return i >= start && i < end;
})  : Ouvrage_vide



try{

  
}catch (e) {

}



//   const getData = () => {
//     if (sortColumn && sortType) {
//       return datas.sort((a, b) => {
//         let x = a[sortColumn];
//         let y = b[sortColumn];
//         if (typeof x === 'string') {
//           x = x.charCodeAt();
//         }
//         if (typeof y === 'string') {
//           y = y.charCodeAt();
//         }
//         if (sortType === 'asc') {
//           return x - y;
//         } else {
//           return y - x;
//         }
//       });
//     }
//     return datas;
//   };

  // const handleSortColumn = (sortColumn, sortType) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     setSortColumn(sortColumn);
  //     setSortType(sortType);
  //   }, 500);
  // };

  const Modification = ( Ligne ) => {
    if ( TypeConnexion === "Utilisateur"){
      alert(Params.infoDroitInsuffisant)  
      return
    }
    
     setMaSelection(Ligne.idouvrag)
     Store2.session("ligneSelectReceptProv", Ligne)
     props.history.push("/EditNewOuvrage")

  }



  return (
    <div>

    <Table
      height={520}
      loading={loading}
      hover= {true}
     // data={noData ? [] : data}
      bordered={true}
      cellBordered={true}
      virtualized

     // sortColumn={sortColumn}
     // sortType={sortType}
     // onSortColumn={handleSortColumn}

      affixHeader
      affixHorizontalSc

      getData  
      // data={ JSON.stringify( Store2.session("ligneAttente") ) }
       data={ datas }
      // data={ MesOuvrages.length   ? datas : Ouvrage_vide  }
     // data={ Ouvrage_vide }
     // data={ getData() }

     // data={ MesOuvrages }
      onRowClick={data => {
       // console.log(data);
       // alert( data.plancode)
       Modification( data )

      }}
    >
      <Table.Column style={{fontWeight: 'bold'}} width={70} align="center" fixed  >
        <Table.HeaderCell>Id</Table.HeaderCell>
        <Table.Cell dataKey="idouvrage" />
      </Table.Column>

      <Table.Column style={{fontWeight: 'bold'}} width={200} fixed  resizable >
        <Table.HeaderCell>Plan code</Table.HeaderCell>
        <Table.Cell dataKey="plancode" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Type ouvrage</Table.HeaderCell>
        <Table.Cell dataKey="typeouvrage" />
      </Table.Column>

      <Table.Column width={200}  resizable >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >NomPrenomClient</Table.HeaderCell>
        <Table.Cell dataKey="Nomprenomclient" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >NomEcb</Table.HeaderCell>
        <Table.Cell dataKey="nomecb" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Region</Table.HeaderCell>
        <Table.Cell dataKey="region" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Province</Table.HeaderCell>
        <Table.Cell dataKey="province" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Commune</Table.HeaderCell>
        <Table.Cell dataKey="commune" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Village</Table.HeaderCell>
        <Table.Cell dataKey="village" />
      </Table.Column>

      {/* <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Etat ouvrage</Table.HeaderCell>
        <Table.Cell dataKey="etatouvrage" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Observation etat</Table.HeaderCell>
        <Table.Cell dataKey="observation_etat" />
      </Table.Column> */}

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Date fin travaux</Table.HeaderCell>
        <Table.Cell dataKey="datefintravaux" />
      </Table.Column>


      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Date controle qualite</Table.HeaderCell>
        <Table.Cell dataKey="datecontrolequalite" />
      </Table.Column>


      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Date  recept prov</Table.HeaderCell>
        <Table.Cell dataKey="datereceptprov" />
      </Table.Column>

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Date mise service</Table.HeaderCell>
        <Table.Cell dataKey="datemiseservice" />
      </Table.Column>


      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Date recept def</Table.HeaderCell>
        <Table.Cell dataKey="datereceptdef" />
      </Table.Column>


      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >cnx latrine</Table.HeaderCell>
        <Table.Cell dataKey="cnxlat" />
      </Table.Column>


      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Etat latrine</Table.HeaderCell>
        <Table.Cell dataKey="etalat" />
      </Table.Column>


      {/* <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Hangare existe</Table.HeaderCell>
        <Table.Cell dataKey="hangarexiste" />
      </Table.Column>
      

      <Table.Column width={200}  >
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Certificat existe</Table.HeaderCell>
        <Table.Cell dataKey="certificatexiste" />
      </Table.Column> */}


      <Table.Column width={100} fixed="right">
        <Table.HeaderCell style={{fontWeight: 'bold', color: 'blue'}} >Action</Table.HeaderCell>

        <Table.Cell>
          {rowData => {
            function handleAction() {
              //alert(`id:${rowData.idouvrage}`);
              // setMaSelection(rowData.idouvrag)
              // Store2.session("ligneSelectReceptProv", rowData)
              // props.history.push("/Edit_ReceptProv")

            }
            return (
              <span>
                <a style={{fontWeight: 'bold', color: 'red'}} onClick={handleAction}> Modifier </a> 
              </span>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>

<div style={{ padding: 20 }}>
  <Pagination
    prev
    next
    first
    last
    ellipsis
    boundaryLinks
    maxButtons={5}
    size="xs"
    layout={['total', '-', 'limit', '|', 'pager', 'skip']}
    total={ MesOuvrages.length }
    limitOptions={[ 20, 50, 100, 250, 500, 1000]}
    limit={limit}
    activePage={page}
    onChangePage={setPage}
    onChangeLimit={handleChangeLimit}
  />
</div>
</div>

  );
};



 const loadDataParam = async () => {
///b alert('ooooooooooooooooooo')


Store2.session("DateD", moment(DateDebut).format("YYYY-MM-DD") ); 
Store2.session("DateF",  moment(DateFin).format("YYYY-MM-DD") ); 

  recherche()


}




const Nouveau = () => {
  //	e.preventDefault();
  //const  history = useHistory();
  // alert( 'ID = ' + MaSelection)

  Store2.session("ligneSelectReceptProv", "")
  props.history.push("/EditCreation")


}



const Lignes =  Store2.session("ligneAttente")

//alert (JSON.stringify(Lignes))


const ChangeDateDebut = (value) => {
setDateDebut( new Date(value) )
}

const ChangeDateFin  = (value) => {
  setDateFin( new Date(value) )
}

                      
const ExportationExcel = () => {

  const Donnees =  Store2.session("ligneAttente"); 
 //const Donnees =  ListeOuvrage

 

  return (
    <ExcelFile element={
    <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
    Exporter
    </Button>}>
                <ExcelSheet filename ="ExportMiseEnService" data={Donnees} name="Receptprov">
                    <ExcelColumn label="plancode" value="plancode"/>
                    <ExcelColumn label="typeouvrage" value="typeouvrage"/>
                    <ExcelColumn label="NomPrenomClient" value="Nomprenomclient"/>
                    <ExcelColumn label="NomPrenomMacon" value="Nomprenommacon"/>
                    <ExcelColumn label="NomEcb" value="nomecb"/>

                    <ExcelColumn label="etatouvrage" value="etatouvrage"/>
                    <ExcelColumn label="Region" value="region"/>
                    <ExcelColumn label="Province" value="province"/>
                    <ExcelColumn label="Commune" value="commune"/>
                    <ExcelColumn label="Village" value="village"/>
                    <ExcelColumn label="observation" value="observation"/>
                    <ExcelColumn label="datefintravaux" value="datefintravaux"/>
                    <ExcelColumn label="datecontrolequalite" value="datecontrolequalite"/>
                    <ExcelColumn label="datereceptprov" value="datereceptprov"/>
                    <ExcelColumn label="datemiseservice" value="datemiseservice"/>
                    <ExcelColumn label="datereceptdef" value="datereceptdef"/>
                    <ExcelColumn label="etalat" value="etalat"/>
                    <ExcelColumn label="connexion latrine" value="cnxlat"/>
                    <ExcelColumn label="hangarexiste" value="hangarexiste"/>
                    <ExcelColumn label="certificatexiste" value="certificatexiste"/>


{/** 
 
 
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>

*/}
                </ExcelSheet>
{/** 
 
                 <ExcelSheet  filename ="ExportGarbal" data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>

*/}
            </ExcelFile>
);





}




const MaRegion =   Store2.session("Region")
const Donnees= Store2.session("ligneAttente")
const MotifGarbal =   Store2.session("MotifGarbal")

 ////////////// alert( ListeOuvrage )

 

 const DateD  =  moment(DateDebut).format("YYYY-MM-DD")
 const DateF  =  moment(DateFin).format("YYYY-MM-DD")

 const { data : ListeOuvrage, ListeDonnees  , loading, error, refetch, recherche, filtrage, searchFilter  } = useFetch(
   Params.Api + '/api/dsh_ReceptProvActive/?token=' + Token + '&region=' + RegionSelect ,
   Params.Api +  `/api/dsh_ReceptProvparamsActive/?token=` + Token + '&region=' + RegionSelect  , Recherche,
   Params.Api +  `/api/dsh_ReceptProvFilterActive/?token=` + Token + '&valeur=' + Recherche  ,
   "ligneAttente"  
 );

 
 const  MonFiltrage = (STR) => {

  // alert( Recherche )
  // alert( STR )
  setRecherche( STR )
  //filtrage()
  searchFilter()
 
   // const [MesOuvrages, setMesOuvrages] = React.useState(Ouvrage_vide);
 
   // setTimeout(() => {
   //   setMesOuvrages(  Store2.session("ligneAttente") ) 
   // }, 2000);
 
   //           // const filteredElements = MesOuvrages
   //           // .filter(plancode => plancode.includes(STR))
 
   // MesOuvrages.filter(plancode => plancode.includes( STR ))
 
   // setMesOuvrages( MesOuvrages ) 
 
 }

 
 const InputSearch = () => {
  return (
    <div >
   
      <FormInput 
      	placeholder="LaRecherche"
        value={Recherche}
        //type= "search" 
        onChange={(e) => { 
            setRecherche(e.target.value)  
             if ( Recherche.length > 2 ){
              // alert ("Logueur = " + Recherche.length )
              // MonFiltrage( e.target.value )
             }

        }}
      />


  
      {/* <Button>R</Button> */}
    </div>
  );
};
 


if (Monerror) {
 // alert( " Verifier la connexion internet SVP ");
  return(
    <>
     <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
    <PageTitle sm="4" title="Gestion des ouvrages en attente" subtitle="Liste de saisies" className="text-sm-left" />
   </Row>



    <div> Verifier la connexion internet SVP ...  </div>
    </Container>
    
    </>
  )


}




if (Chargement ){

  return(
    <>
       <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
    <PageTitle sm="4" title="Gestion des ouvrages en attente" subtitle="Liste de saisies" className="text-sm-left" />
   </Row>

    <div> CHARGEMENT EN COURS ...  </div>
    </Container>
    
    </>
  )

}else{
  const Donnees =  Store2.session("ligneAttente"); 



  /// A PARTIR D'ICI C'EST OK ON AFFICHE
    
    if ( Token  === undefined  ||  Token  === "" || TypeConnexion === "Partenaire"   || TypeConnexion === "Opérateur Garbal" ) {
      //Affichage de la redirection
     // alert('  Erreur : Verifier votre Email / Mot de passe. ')
      return <Redirect to='/'/>;
    
    }
    
  
  
    const today = new Date();
    const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const twoDaysAgo = (d => new Date(d.setDate(d.getDate() - 2)))(new Date());
  
  
    return (
      <>
  



      <Container fluid className="main-content-container px-4">
   {/* Page Header */}
   <Row noGutters className="page-header py-4">
     <PageTitle sm="4" title="Gestion des ouvrages en attente" subtitle="Liste de saisies" className="text-sm-left" />
   </Row>
  
           
           <Card small className="mb-4">
           <CardHeader className="border-bottom">
           <CardBody className="p-0 pb-3">
  
  
   <Row>   
  
      <Col  md="12">
  
          <ButtonToolbar>

    
                    <ButtonGroup size="sm">
    
                    <ExportationExcel />

{/*                                           
                    <Button onClick={() => Nouveau()} marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Nouveau
                      </Button>
 */}

<Button onClick={() => {
                        // refetch()
                        AfficheMap()
                        
                      }} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >
                        <FaRegMap style={{color: 'blue' , fontSize: '20px' }}/> 
                        
                      </Button>     
                          {/* Raffraichir() */}
                      <Button onClick={() => {
                        // refetch()
                        Rafraichissement()
                        
                      }} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >
                        <FaSyncAlt style={{color: 'blue' , fontSize: '20px' }}/> 
                        
                      </Button>      
    
                                            {/* loadDataParam() */}
                      <Button onClick={() => { 
                        RechercheDate() 
                        // alert("oooooooooooooooo")
                        /// loadDataParam()
                      }}
                      
                     marginRight={8} 
                      outline theme="dark" className="mb-2 mr-1" >
                          <FaSistrix style={{color: 'red' , fontSize: '20px' }}/> 
                      </Button>
    
    
                    </ButtonGroup>


                      
        <InputGroup size="sm" className = "d-flex my-auto date-range">
    
    
    <InputGroupAddon type="append">
  
            
          <InputGroupText> 
          <FaSistrix style={{color: 'black' , fontSize: '15px' }}/>  
          </InputGroupText>
  
        </InputGroupAddon>
  
  
        {/* <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
         */}


         
<div>
        <FormInput
						id="DateFinTrx"
						value={ DateDebut }
						type="date"
            style={{borderColor:'#ff0000', 
                borderwidth: 15
               // paddingRight: 10, paddingLeft: 10
              }} 
						onChange={(e) => {
						setDateDebut(e.target.value)
							
						}}

					/>
</div>

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>


<div>
          <FormInput
						id="DateFinTrx"

						value={ DateFin }
						type="date"
            style={{borderColor:'#ff0000', 
                borderwidth: 15
               // paddingRight: 10, paddingLeft: 10
              }} 
						onChange={(e) => {
						setDateFin(e.target.value)
							
						}}

					/>

</div>
  
  <label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>


        {/* <InputSearch /> */}   
<div>
        <FormInput 
      	placeholder="Mot clé "
        value={Recherche}
        //type= "search" 
        style={{borderColor:'#000000', 
               // paddingRight: 10, paddingLeft: 10
              }} 
        onChange={(e) => { 
            setRecherche(e.target.value)  
             if ( Recherche.length > 2 ){
              // alert ("Logueur = " + Recherche.length )
              // MonFiltrage( e.target.value )
             }
            
        }}
        
        onKeyDown={(e) => { 
          if (e.key === 'Enter') {
           // alert('Touche ENTRER');
           RechercheSaisie( Recherche )
          }
          
      }}

        

      />

</div>

  {/* <Elasticsearch url = "http://5.183.8.94:4040/Region" >
    <SearchBox id="mainSearch" />
        <Results
          id="Result"
          items={data => data.map(item => <div>{item.nom}</div>)}
        />

      </Elasticsearch> */}


    


      
    </InputGroup>
  



    
        </ButtonToolbar>
    
    
    
    
            <div>
              <br/>
          </div>
  
  
  
       </Col>
               
  
  
  </Row>
  
  
  
               {/* <MDBDataTableV5  responsive autoWidth striped
                   hover
                   entriesOptions={[10, 20, 30, 50, 100]}
                   entries={100}
                   pagesAmount={4}
                  // pagingTop
                   small
                   bordered
                   //striped
                  // scrollX
                 // scrollY
                   
                   
                  exportToCSV proSelect 
                   fullPagination 
                   filter='office'  
                   searchTop searchBottom={false}


                 //  data={datatable}
                   data={ListeDonnees}
                   checkbox
                   headCheckboxID='id2'
                   bodyCheckboxID='checkboxes2'
                   getValueCheckBox={(e) => {
                   showLogs2(e);
                   }}
  
               /> */}
  
          < Tableau />

           </CardBody>
           </CardHeader>


    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>



           </Card>
  
  
  
  
  
  
  {
    //'oooooooooooooooooooooAAA \n' + JSON.stringify(checkbox1).name
  ///  Store2.session("LigneSelect", checkbox1.idouvrage) 
  }
  
  
  {
              //  ListeOuvrage.map((user) => (
  
              //    <li> { user.idouvrage} { " " + user.label} { " " + user.plancode}</li> 
        
              // ))
  
  }
  


  
  
  </Container>
  
   </>
    );



}








}

export default ListActiveOuvrahe;