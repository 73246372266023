import React, { useState, useEffect, Fragment } from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,Row,Col,
  FormSelect
} from "shards-react";

import Store2 from "store2"; 
import axios from 'axios';
import Params from "../../../Pages/Config/Params"; 

export default () => {

  
  const [MaRegion, setMaRegion] = useState('')
  const [MaProvince, setMaProvince] = useState('')
  const [MaCommune, setMaCommune] = useState('')
  const [MonVillage, setMonVillage] = useState('')


  const [Region, setRegion] = useState('')
  const [Province,setProvince] = useState( '')
  const [Commune,setCommune] = useState( '')
  const [Village,setVillage] = useState( '')




  const Token = Store2.session("token" ) 

  const TypeConnexion = Store2.session("TypeConnexion" ) 


  useEffect(() => {


       //  Recupeartion()

       
    }, []);




  const Recupeartion = () => {

    

// GET REGION
	axios.get( Params.Api + '/api/region/?token=' + Token )
	.then(function (response) {

    // Store2.session("Region", response.data )
    // localStorage.setItem('Name', 'GANSBEOGO' );
    setMaRegion( response.data )
	  })
	  .catch(function (error) {
		if (error.response) {

			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);


		}  else if (error.request){

			console.log(error.request);

		}else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		  }

		alert(`Erreur API Region : constacter l'administrateur \n ` + error.message );
	  });

    
    // GET PROVINCE
	axios.get( Params.Api  + '/api/province/?token=' + Token )
  
  .then(function (response) {

  // Store2.session("Province", response.data )
  setMaProvince( response.data )
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Province : constacter l'administrateur \n ` + error );
  });


      // GET COMMUNE
	axios.get( Params.Api  + '/api/commune/?token=' + Token )
  
  .then(function (response) {

  // Store2.session("Commune", response.data )
  setMaCommune( response.data  )
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Commune: constacter l'administrateur \n ` + error.message );
  });


      // GET VILLAGE
	axios.get( Params.Api  + '/api/village/?token=' + Token )
  
  .then(function (response) {

  // Store2.session("Village", response.data )
  MonVillage( response.data  )
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Village : constacter l'administrateur \n ` + error.message );
  });



  }





return (

  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">



  </Form>
);

}

