import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  DatePicker,
  Alert,
} from "shards-react";
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params"; 
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select'
import { Redirect, Link  } from "react-router-dom";

import { Album, Dialog  } from 'uxcore';
import { Button as MonButton } from 'uxcore';

const { Photo } = Album;

			
const F_SAV = (props) =>{

const Token = Store2.session("token" ) 
const TypeConnexion = Store2.session("TypeConnexion" ) 


const LigneSuiviClassic =  Store2.session("ligneSelectSuiviClassic")
 // alert( JSON.stringify(LigneSuiviClassic) )
////  alert( JSON.stringify ( LigneSuiviClassic.Province ))
const [IdOuvrage,setIdOuvrage] = useState(0)

const [MonID,setMonID] = useState(  LigneSuiviClassic ? LigneSuiviClassic.idsuiviouvr : 0)
const [Region,setRegion] = useState(  LigneSuiviClassic ? LigneSuiviClassic.Region : '')
const [Province,setProvince] = useState(  LigneSuiviClassic ? LigneSuiviClassic.Province : '')
const [Commune,setCommune] = useState(  LigneSuiviClassic ? LigneSuiviClassic.Commune : '')
const [Village,setVillage] = useState(  LigneSuiviClassic ? LigneSuiviClassic.Village : '')
const [Observation,setObservation] = useState(  LigneSuiviClassic ? LigneSuiviClassic.observ_ouvrage : '')

const [PlanCode,setPlanCode] = useState(  LigneSuiviClassic ? LigneSuiviClassic.plancode : '')
const [TypeOuvrage,setTypeOuvrage] = useState(  LigneSuiviClassic ? LigneSuiviClassic.typeouvrage : '')
const [NomPrenClient,setNomPrenClient] = useState(  LigneSuiviClassic ? LigneSuiviClassic.NomPrenomClient : '')
const [NomPrenMacon,setNomPrenMacon] = useState(  LigneSuiviClassic ? LigneSuiviClassic.NomPrenomMacon : '')
const [NomEcb,setNomEcb] = useState(  LigneSuiviClassic ? LigneSuiviClassic.NomEcb : '')

const [EtatOuvrage,setEtatOuvrage] = useState( LigneSuiviClassic ? LigneSuiviClassic.etatouvrage : '')
const [ObsEtat,setObsEtat] = useState( LigneSuiviClassic ? LigneSuiviClassic.observation_etat : '')


const [EtatLampe,setEtatLampe] = useState( LigneSuiviClassic ? LigneSuiviClassic.etatlampe : '')
const [UsageLampe,setUsageLampe] = useState( LigneSuiviClassic ? LigneSuiviClassic.usagelampe : '')
const [EtatFoyer,setEtatFoyer] = useState( LigneSuiviClassic ? LigneSuiviClassic.etatfoyer : '')
const [UsageFoyer,setUsageFoyer] = useState( LigneSuiviClassic ? LigneSuiviClassic.usagefoyer : '')
const [NbJrHrsFonct,setNbJrHrsFonct] = useState( LigneSuiviClassic ? LigneSuiviClassic.nbrtpsnonfct : 0)
const [QteCompDispo,setQteCompDispo] = useState( LigneSuiviClassic ? LigneSuiviClassic.qtecompostdispo : 0)
const [QteCompDispoVente,setQteCompDispoVente] = useState( LigneSuiviClassic ? LigneSuiviClassic.qtecompostdispovente : 0)
const [HangareExiste,setHangareExiste] = useState( LigneSuiviClassic ? LigneSuiviClassic.hangareexiste : '')

const [ProdComposte,setProdComposte] = useState( LigneSuiviClassic ? LigneSuiviClassic.prodcompost : '')
const [PerodeChargement,setPerodeChargement] = useState( LigneSuiviClassic ? LigneSuiviClassic.periodchargement : '')
const [NbHrUseGaz,setNbHrUseGaz] = useState( LigneSuiviClassic ? LigneSuiviClassic.gazsuffisant : 0)
const [PersContacte,setPersContacte] = useState( LigneSuiviClassic ? LigneSuiviClassic.perscontactee : '')
const [NomContact,setNomContact] = useState( LigneSuiviClassic ? LigneSuiviClassic.nomContact : '')
const [Intervension,setIntervension] = useState( LigneSuiviClassic ? LigneSuiviClassic.isIntervention : '')
const [DateIntervension,setDateIntervension] = useState( LigneSuiviClassic ?  new Date (LigneSuiviClassic.dateintervention)   : new Date () )


const [DateSuivi,setDateSuivi] = useState( LigneSuiviClassic ?  new Date (LigneSuiviClassic.datesuivi)   : new Date () )
const [DateRdv,setDateRdv] = useState( LigneSuiviClassic ?  new Date (LigneSuiviClassic.daterdv)   : new Date () )

const [PhotoFoyer, setPhotoFoyer] = useState( LigneSuiviClassic. photo_foyer )
const [PhotoOuvrage, setPhotoOuvrage] = useState( LigneSuiviClassic.photo_ouvrage)
const [PhotoCertificat, setPhotoCertificat] = useState( LigneSuiviClassic.photo_certficat)




const [GPS_Lat,setGPS_Lat] = useState( LigneSuiviClassic ? LigneSuiviClassic.latitude   : ' ')
const [GPS_Long,setGPS_Long] = useState( LigneSuiviClassic ? LigneSuiviClassic.longitude   : ' ')

const GPS_Lien = 'http://gps.biosynchropnbbf.net/?lon=' + GPS_Lat + '&lat='+ GPS_Long



const ModificationData = async (Donnees) => {


	axios.post( Params.Api + '/api/dsh_suiviclassic_modif/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	window.location.href = '/Suivi_Classic' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }

  
const InsertionData = async (Donnees) => {


	axios.post( Params.Api + '/api/post_suivi_classic/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	window.location.href = '/Suivi_Classic' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }



const Enregistrer = () => {
  //this.props.history.push("/Accueil")
 // alert (MonID)

 
 if (   TypeConnexion === "Utilisateur"){
    alert(Params.infoDroitInsuffisant)  
    return
  }


  if( MonID === 0 ){
// alert ('Nouveau')
//  moment(DateRdv).format("YYYY-MM-DD HH:mm:ss"),



	var Donnees = {
		IdOuvrage  : IdOuvrage,
		// Idsuiviouvr  : LigneSuiviClassic.idsuiviouvr,
		Etat  : EtatOuvrage ,  
		EtatFoyer  : EtatLampe ,
		UsageFoyer  : UsageLampe ,
		EtatLapme  : EtatFoyer ,
		UsageLapme  : UsageFoyer ,
		DateSuivi  : moment(DateSuivi).format("YYYY-MM-DD"),
		QteCompDispo  : QteCompDispo ,
		QteCompDispoVte  : QteCompDispoVente ,
		//NbJrFonct  : NbJrHrsFonct ,
		NbJrHorsFonct  : NbJrHrsFonct ,
		HangareDispo : HangareExiste  ,
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		ProdComposte :   ProdComposte,
		PeriodeCharge :   PerodeChargement,
		NbHrGaz : NbHrUseGaz,
		PersContacte : PersContacte,
		NomContact : NomContact,
		Intervension : Intervension,
		DateIntervension : moment(DateIntervension).format("YYYY-MM-DD"),
		DateRDv  : moment(DateRdv).format("YYYY-MM-DD")
	 
	  };

	InsertionData( Donnees )

  }else{


	var Donnees = {
		IdOuvrage  : IdOuvrage,
		Idsuiviouvr  : LigneSuiviClassic.idsuiviouvr,
		Etat  : EtatOuvrage ,  
		EtatFoyer  : EtatLampe ,
		UsageFoyer  : UsageLampe ,
		EtatLapme  : EtatFoyer ,
		UsageLapme  : UsageFoyer ,
		DateSuivi  : moment(DateSuivi).format("YYYY-MM-DD"),
		QteCompDispo  : QteCompDispo ,
		QteCompDispoVte  : QteCompDispoVente ,
		//NbJrFonct  : NbJrHrsFonct ,
		NbJrHorsFonct  : NbJrHrsFonct,
		HangareDispo : HangareExiste  ,
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		ProdComposte :   ProdComposte,
		PeriodeCharge :   PerodeChargement,
		NbHrGaz : NbHrUseGaz,
		PersContacte : PersContacte,
		NomContact : NomContact,
		Intervension : Intervension,
		DateIntervension , DateIntervension,

		DateRDv  : moment(DateRdv).format("YYYY-MM-DD")

	  };
	
	  ModificationData( Donnees )

  }


 // alert( JSON.stringify(Donnees) )
  //alert( "Mise à jour terminée avec succès")

}


const ChangeDateRDV = (value) => {
	setDateRdv( new Date(value) )
}

const ChangeDateSuivi = (value) => {
	setDateSuivi( new Date(value) )
}

const ChangeDateIntervension = (value) => {
	setDateIntervension( new Date(value) )
}

// ----------------------------------------

  
const  handleClick = () => {
	//Album.showButton(true)
	//alert( process.env.PUBLIC_URL + "/photos/img1.jpg")

	// <Image source={{uri: 'https://reactjs.org/logo-og.png'}}
	// style={{width: 400, height: 400}} />
//----------------------
	//  alert(  Lien + PhotoCertificat )
	// <Album showButton width={400} height={200} enableKeyBoardControl {...this.state.values}>
    
	const Lien = Params.DomainePhoto
	
	Album.show({
      photos: [  
        <Photo
          src={ Lien +  PhotoFoyer}
          key={0}
        />,
        <Photo 
		src={ Lien + PhotoCertificat} key={1} />,
		<Photo 
		src={ Lien + PhotoOuvrage}  key={2} />,
        
      ],
    });
  }
// () => <Redirect to="/GARBALw" />

 const  OkInsertion = () => {
	Dialog.info({
	  title: 'BioSynchro',
	  content: 'Insertion Terminé',
	  onOk() {  },
	});
  }
// -------------------------------------------------


const AffichageValeur = (MaListe, Valeur) => {
	//alert( Valeur)
	//alert( MaListe[1].plancode)
	// var Donnees =  MaListe.filter( (item) => {
	// 	return item.plancode == Valeur;
		
	// });

	let Donnees = MaListe.filter(item => item.plancode === Valeur);

	//alert( JSON.stringify(Donnees) )
	setTypeOuvrage(   Donnees[0].typeouvrage  )
	setNomPrenClient(  Donnees[0].NomPrenomClient )
	setNomPrenMacon(  Donnees[0].NomPrenomMacon )
	setNomEcb(  Donnees[0].NomEcb )

	setRegion(  Donnees[0].Region )
	setProvince(  Donnees[0].Province )
	setCommune(  Donnees[0].Commune )
	setVillage(  Donnees[0].Village )
	setIdOuvrage( Donnees[0].idouvrage )

}




  const options = Store2.session("ouvrageinfo" )
  const ouvrageinfoall = Store2.session("ouvrageinfoall" )
  
  //alert(JSON.stringify( ouvrageinfoall[1].plancode) )
// const options = [
// 	{ value: 'chocolate', label: 'Chocolate' },
// 	{ value: 'strawberry', label: 'Strawberry' },
// 	{ value: 'vanilla', label: 'Vanilla' }
//   ]

  const PlacodeNew = () => (
	<Select
	 options={options}
	 defaultValue={PlanCode}
	// onChange={setSelectedOption}
	 onChange={(x) => {

		//setPlanCode(x.value)
		AffichageValeur( ouvrageinfoall , x.label)
		
		}}


	  />
  )
  
  const PlacodeModif = () =>{
	  return (

		<FormInput
		id="plancode"
		placeholder="plan code"
		invalid  
		/* required */
		value={PlanCode} 
		onChange={(e) => {

		//		setPlanCode(e.target.value)

		}}
	  />

  )}
  
  
  const AffichePhoto = () => (

	<MonButton  onClick={() => handleClick() } >Afficher </MonButton>
							
		  )


//-----------------------------


const  title = "Détails de saisie"

  return (
	  <Card small className="mb-4" >
	  

            { /**
             <Alert theme="success">
                    aaaaaaaaaaaa
                </Alert>

             */}
		
		<ListGroup flush>
		
		  <ListGroupItem className="p-3">
			<Row>
			  <Col>
				<Form  // onSubmit={this.onEnvoyer}
				>
				  <Row form>
					{/* plancode */}
					<Col md="6" className="form-group">
					  <label htmlFor="plancode" >Plan code</label>
	
	  { MonID === 0 ? PlacodeNew() : PlacodeModif()}

					</Col>
					{/* typeouvrage */}
					<Col md="6" className="form-group">
					  <label htmlFor="typeouvrage">Type ouvrage</label>
					  <FormInput
						id="typeouvrage"
						placeholder="typeouvrage"
						invalid 
						value={TypeOuvrage} 
						onChange={(e) => {
							// if (MonID === 0){
							// 	setTypeOuvrage(e.target.value)
							// }
							
						}}
					  />
					</Col>
				  </Row>

				  <Row form>
					{/* NomPrenomClient */}
					<Col md="4" className="form-group">
					  <label htmlFor="NomPrenomClient">Nom Prenom Client</label>
					  <FormInput
						id="NomPrenomClient"
						placeholder="NomPrenomClient"
						invalid 
						value= {NomPrenClient}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomPrenClient(e.target.value)
							// }

						}}
						
					  />
					</Col>

					{/* NomPrenomMacone */}
					<Col md="4" className="form-group">
					  <label htmlFor="NomPrenomMacon">Nom Prenom Macon</label>
					  <FormInput
						id="NomPrenomMacon"
						placeholder="NomPrenomMacon"
						invalid 
						/* required */
						value={NomPrenMacon}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomPrenMacon(e.target.value)
							// }	
						}}
					  />
					</Col>

					{/* NomEcb */}
					<Col md="4" className="form-group">
					<label htmlFor="NomEcb">Nom Ecb</label>
					  <FormInput
						id="NomEcb"
						placeholder="NomEcb"
						invalid 
						/* required */
						value={NomEcb}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomEcb(e.target.value)
							// }
							
						}}
					  />
					</Col>
				  </Row>

				  <Row>

					{/* Region */}
					<Col md="3" className="form-group">
					<label htmlFor="Region">Region</label>
					  <FormInput
						id="Region"
						placeholder="Region"
						invalid 
						/* required */
						value={Region}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setRegion(e.target.value)
							// }
							
						}}
					  />
					</Col>
					{/* Province */}
					<Col md="3" className="form-group">
					  <label htmlFor="Province">Province</label>
					  <FormInput
						id="Province"
						placeholder="Province"
						invalid 
						value={Province}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setProvince(e.target.value)
							// }
							
						}}
						/*onChange={() => {}} */
					  />
					</Col>

					{/* commune */}
					<Col md="3" className="form-group">
					<label htmlFor="commune">Commune</label>
					  <FormInput
						id="commune"
						placeholder="commune"
						invalid 
						/* required */
						value={Commune}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setCommune(e.target.value)
							// }
							
						}}
					  />
					</Col>
					
					{/* Village */}
					<Col md="3" className="form-group">
					  <label htmlFor="Village">Village</label>
					  <FormInput
						id="Village"
						placeholder="Village"
						invalid 
						value={Village}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setVillage(e.target.value)
							// }
							
						}}
						/*onChange={() => {}} */
					  />
					</Col>
				  </Row>
 
				  <Row form>

					{/* DateSAV */}
					<Col md="2" className="form-group">
					  <label htmlFor="DateSuivi">Date de suivi</label>
					  <DatePicker
					 //  placeholderText="-"
					 // className="form-control"
					 //  showDisabledMonthNavigation
					 dateFormat="dd-MM-yyyy hh:mm:ss"
					selected={DateSuivi}  
					 selectsStart  
					 startDate={ new Date() }
					 // endDate={this.state.endDate}
					 onChange={date => ChangeDateSuivi(date)}
					 // autosuggest="off"
					 dropdownMode="select"

					/>
						
					</Col>
				  {/* DateRDV */}
					<Col md="2" className="form-group">
					  <label htmlFor="DateRDV">Date du prochain RDV</label>
					  <DatePicker
					 //  placeholderText="-"
					 // className="form-control"
					 //  showDisabledMonthNavigation
					 dateFormat="dd-MM-yyyy hh:mm:ss"
					selected={DateRdv}  
					 selectsStart  
					  startDate={ new Date() }
					 // endDate={this.state.endDate}
					 onChange={date => ChangeDateRDV(date)}
					 // autosuggest="off"
					 dropdownMode="select"

					/>
						
					</Col>
				 
				 {/* Etatouvrage */}
					<Col md="2" className="form-group">
					<label htmlFor="Etatouvrage">Etat ouvrage</label>
					  <FormSelect id="Etatouvrage"
					  /* required */
					  value={EtatOuvrage} 
						onChange={(e) => {
							setEtatOuvrage(e.target.value)
						}}
					  >
						<option></option>
						<option>Fonctionnel</option>
						<option>Non fonctionnel</option>
						<option>Nouveau chargement</option>
	
					  </FormSelect>
					 </Col>

 					{/* observation_etat */}
					  <Col md="2" className="form-group">
					<label htmlFor="observation_etat">Observation état</label>
					  <FormSelect id="observation_etat"
					  /* required */
					  value={ObsEtat} 
						onChange={(e) => {
							setObsEtat(e.target.value)
						}}
					  >
						<option>Auccun</option>
						<option>Abandon</option>
						<option>Foyer non utilisé</option>
						<option>Foyer non utilisé</option>
						<option>Lampe et/ou foyé usagé</option>
						<option>Lampe non utilisée</option>
						<option>Mauvaise qualite de bouse</option>
						<option>Non chargé</option>
						<option>Non mis en service</option>
						<option>Non respect du chargement quotidien</option>
						<option>Pas d'accessoires installés</option>
						<option>Pas de production de compost</option>
						<option>Perte d'animaux</option>
						<option>Problème technique (fuite de réseau ou fuite dôme)</option>
						<option>Décès</option>
						<option>Arrêt de chargement</option>

	
						

					  </FormSelect>
					  </Col>

					{/* GazSuffisant */}
					<Col md="2" className="form-group">
					<label htmlFor="GazSuffisant">Gaz suffisant </label>
					<FormSelect id="GazSuffisant"
						/* required */
						value={""} 
						onChange={(e) => {
							//setKitDispo(e.target.value)
						}}
						>
						<option></option>
						<option>Oui</option>
						<option>Non</option>

						</FormSelect>
						</Col>

					{/* ProdComposte */}
					<Col md="2" className="form-group">
					<label htmlFor="ProdComposte">Production compost </label>
					<FormSelect id="ProdComposte"
						/* required */
						value={ProdComposte} 
						onChange={(e) => {
							setProdComposte(e.target.value)
						}}
						>
						<option></option>
						<option>Oui</option>
						<option>Non</option>

						</FormSelect>
						</Col>


				  </Row>



  <Row form>


   {/* Etatlampe */}
   <Col md="2" className="form-group">
   <label htmlFor="Etatlampe">Etat lampe</label>
	 <FormSelect id="Etatlampe"
	 /* required */
	 value={EtatLampe} 
	   onChange={(e) => {
		setEtatLampe(e.target.value)
	   }}
	 >
	   <option></option>
	   <option>Bon</option>
	   <option>Mauvais</option>

	 </FormSelect>
	   
   </Col>

 {/* UsageLampe */}
   <Col md="2" className="form-group">
	 <label htmlFor="UsageLampe">Usage lampe</label>
	 <FormSelect id="UsageLampe"
	 /* required */
	 value={UsageLampe} 
	   onChange={(e) => {
		setUsageLampe(e.target.value)
	   }}
	 >
		<option></option>
	   <option>Oui</option>
	   <option>Non</option>

	 </FormSelect>
	   
   </Col>

 {/* Etatfoyer */}
   <Col md="2" className="form-group">
   <label htmlFor="Etatfoyer">Etat foyer</label>
   <FormSelect id="Etatfoyer"
	 /* required */
	 value={EtatFoyer} 
	   onChange={(e) => {
		setEtatFoyer(e.target.value)
	   }}
	 >
	   <option></option>
	   <option>Bon</option>
	   <option>Mauvais</option>

	 </FormSelect>
	</Col>

 {/* UsageFoyer */}
	 <Col md="2" className="form-group">
   <label htmlFor="UsageFoyer etat">Usage foyer</label>
   <FormSelect id="UsageFoyer"
	 /* required */
	 value={UsageFoyer} 
	   onChange={(e) => {
		setUsageFoyer(e.target.value)
	   }}
	 >
		<option></option>
	   <option>Oui</option>
	   <option>Non</option>

	 </FormSelect>

	 </Col>


{/* PeriodeChargement */}
<Col md="2" className="form-group">
   <label htmlFor="PeriodeChargement">Période de chargement </label>
   <FormSelect id="PeriodeChargement"
	 /* required */
	 value={PerodeChargement} 
	   onChange={(e) => {
		setPerodeChargement(e.target.value)
	   }}
	 >
	   <option></option>
	   <option>Chaque jour</option>
	   <option>Chaque deux jour</option>
	   <option>Deux fois par semaine</option>
	   <option>Au besoin</option>

	 </FormSelect>
	</Col>


 </Row>



 <Row form>

{/* NbJrHorsFonct */}
 <Col md="2" className="form-group">
   <label htmlFor="NbJrHorsFonct">Nb jour hors fonct.</label>
   <FormInput
		id="NbJrHorsFonct"
		placeholder=""
		type="number"
		value={NbJrHrsFonct}  
		onChange={(e) => {
			setNbJrHrsFonct(e.target.value)
		}}

		/>
	</Col>


   {/* NbHeurUtilisationGaz */}
   <Col md="2" className="form-group">
   <label htmlFor="NbHeurUtilisationGaz">Nb Heure Utilisation de gaz</label>
   <FormInput
		id="NbHeurUtilisationGaz"
		placeholder=""
		type="number"
		value={NbHrUseGaz}  
		onChange={(e) => {
			setNbHrUseGaz(e.target.value)
		}}

		/>
	   
   </Col>

   {/* QteCompDispo */}
   <Col md="2" className="form-group">
   <label htmlFor="QteCompDispo">Quantité comp dispo</label>
   <FormInput
		id="QteCompDispo"
		placeholder=""
		type="number"
		value={QteCompDispo}  
		onChange={(e) => {
			setQteCompDispo(e.target.value)
		}}

		/>
	   
   </Col>

 {/* QteCompDispoVente */}
   <Col md="2" className="form-group">
   <label htmlFor="QteCompDispoVente">Qte comp dispo pr vente</label>
	 <FormInput
		id="QteCompDispoVente"
		placeholder=""
		type="number"
		value={QteCompDispoVente}  
		onChange={(e) => {
			setQteCompDispoVente(e.target.value)
		}}

		/>
	   
   </Col>


 {/* PresenceHangare */}
	 <Col md="2" className="form-group">
	 <label htmlFor="PresenceHangare">Présence hangar</label>
   <FormSelect id="PresenceHangare"
	 /* required */
	 value={HangareExiste} 
	   onChange={(e) => {
		setHangareExiste(e.target.value)
	   }}
	 >
		<option></option>
	   <option>Oui</option>
	   <option>Non</option>

	 </FormSelect>

	 </Col>

 </Row>




 <Row form>


{/* PersonneContacte */}
<Col md="2" className="form-group">
<label htmlFor="PersonneContacte">Personne contactée</label>
  <FormSelect id="PersonneContacte"
  /* required */
  value={PersContacte} 
	onChange={(e) => {
		setPersContacte(e.target.value)
	}}
  >
	<option></option>
	<option>Oui</option>
	<option>Non</option>

  </FormSelect>
	
</Col>

{/* NomContact */}
<Col md="2" className="form-group">
  <label htmlFor="NomContact">Nom Contact</label>
  <FormInput
		id="NomContact"
		//placeholder="Nom Contact"
		value= {NomContact}
		onChange={(e) => {
			setNomContact(e.target.value)
			}}
						
		/>
	
</Col>

{/* Intervension */}
<Col md="2" className="form-group">
<label htmlFor="Intervension">Intervension</label>
<FormSelect id="Intervension"
  /* required */
  value={Intervension} 
	onChange={(e) => {
		setIntervension(e.target.value)
	}}
  >
	<option></option>
	<option>Oui</option>
	<option>Non</option>

  </FormSelect>
 </Col>

{/* DateIntervension */}
  <Col md="2" className="form-group">
<label htmlFor="DateIntervension etat">Date Intervension</label>
<DatePicker
 //  placeholderText="-"
 // className="form-control"
//  showDisabledMonthNavigation
 dateFormat="dd-MM-yyyy hh:mm:ss"
selected={DateIntervension}  
 selectsStart  
 startDate={ new Date() }
 // endDate={this.state.endDate}
 onChange={date => ChangeDateIntervension(date)}
 // autosuggest="off"
 dropdownMode="select"

/>

  </Col>

	{/* Images */}
	<Col md="2" className="form-group">
	  <label htmlFor="thematique">  {  MonID === 0 ? null : "Images"  } </label>
		<div>
		{ MonID === 0 ? null : AffichePhoto()   }
		</div>

	</Col>


	<Col md="2" className="form-group">
	  <label htmlFor="thematique">  {  MonID === 0 ? null : "Position GPS"  } </label>
		<div>
				{/* <Link to='http://gps.biosynchropnbbf.net/?lon=12.377703&lat=-1.501406' target="_blank" query={{test: '1112233445566'}} >Afficher GPS</Link> */}
				{/* <Link to={GPS_Lien} target="_blank" query={{test: '1112233445566'}} >Afficher GPS</Link> */}

				<a href={GPS_Lien} target="_blank"><b> Afficher GPS </b></a>
				
		
		</div>

	</Col>

</Row>





				  <Row form>
				  
					{/* Observation */}
					<Col md="12" className="form-group">
					  <label htmlFor="Observation">Observation</label>
					  <FormTextarea id="Observation" rows="5" 
					  value= {Observation}
						onChange={(e) => {
							setObservation(e.target.value)
						}}	
					  />

		
		
					</Col>
				  </Row>



				  <Button  onClick={ () => Enregistrer() } 
                                           marginRight={8} theme="success">
                  Enregistrer
                </Button>

				<Button onClick={() =>  window.location.href = '/Suivi_Classic'  } marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Annuler
                      </Button>

				</Form>
			  </Col>
			</Row>
		  </ListGroupItem>
		</ListGroup>
	
	
	  </Card>

        )

}



  
export default F_SAV;
