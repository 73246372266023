module.exports = {

  /**
   Server Burkina  4001
   Photo Burkina

    Server Mali
   Photo Mali

    Server Niger
   Photo Niger
   S
   */
      
    /// App config   http://5.183.8.94:4041 - http://192.168.7.2:4040
      // Api : 'http://192.168.64.1:4040', // http://192.168.7.2:4040 - http://45.15.25.32:2021/ - http://5.183.8.94
       // 192.168.223.1


       // Api : 'http://192.168.223.1:4200',     // http://45.15.25.32:2021/  -- RIIBO
       // Api : 'http://192.168.1.1:4200',     // Avec VM


       
        //////// Api : 'http://5.183.8.94:4200',     // http://45.15.25.32:2021/  -- RIIBO
          /////////////////////// PRODUCTION V3
        // Api : 'http://5.183.8.94:4080',   
        

    //////////////////////// PROFUCTION

    ///  Mali

     Api : 'http://5.183.8.94:4400',  //   Prod
      DomainePhoto : 'http://5.183.8.94/Api_Prod/2024/ApiMali2024/Tools/Photos/uploads/' ,    // pour V3


      ////  Niger

      // Api : 'http://5.183.8.94:4500',  //   Prod
      // DomainePhoto : 'http://5.183.8.94/Api_Prod/2024/ApiNiger2024/Tools/Photos/uploads/' ,  
  

            ////  Burkina Faso

      // Api : 'http://5.183.8.94:4100',  //   Prod
      // DomainePhoto : 'http://5.183.8.94/Api_Prod/ApiBurkina/Tools/Photos/uploads/' ,  
  

      

          //////////////////////// DEVELOPPEMENT
   
      // Api : 'http://127.0.0.1:4400' ,       // Dev    // pour V3
      // DomainePhoto : 'http://localhost/Api_Prod/2024/ApiBurkina/Tools/Photos/uploads/' ,    // pour V3



      // DomainePhoto : 'http://5.183.8.94/Api_Prod/ApiMali/Tools/Photos/uploads/' ,    // pour V3      
      // DomainePhoto : 'http://5.183.8.94/Api_Prod/ApiNiger/Tools/Photos/uploads/' ,    // pour V3

        
    Pays : 'Mali',  
    Api_Orange : 'https://testom.orange.bf:9008/payment',
    MonHost : 'http://localhost/Piiga/slidesPubs/',
    CodeUssd : '*866*4*6*1000%23',
    TextUssd : '*866*4*6*1000#',
    TimeOut : 3000,
    InfoAccueil : 'Bienvenue sur BioSynchro',    
    Version : '1.3',
    InfoPopup :{
             info2 : 'Infos 2',
             info3 : 'Infos 3',
    },
    infoDroitInsuffisant : "Vous n'avez pas cette autorisation. Contacter l'administrateur.",
    Nb_dernierIN : 5,
    Nb_Vue_User : 10,

// Mysql Config
    HOST: "localhost",
    USER: "root",
    PASSWORD: "220011",
    DB: "dbtop",
    dialect: "mysql",
    pool: {
        max: 5,
        min: 0,
        acquire: 30000,
        idle: 10000
      },

  };