import React , {useEffect} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle"; 
// import UserDetails from "../components/user-profile-lite/UserDetails";
import F_CreactionModif from "../NewOuvrage/F_CreactionModif";


const EditCreationOuvrage = (props) => { 

/// const location = useLocation();

 // alert (location.info)
  
 return (

  <Container fluid className="main-content-container px-4">
  <Row noGutters className="page-header py-4">
  <PageTitle sm="4" title="Modification d'un ouvrage " subtitle="Gestion des ouvrahes" className="text-sm-left" />
    </Row>

  <Row>
    
    <Col lg="12"> 

      <F_CreactionModif Donnees = {"oooooooooo"} />

    </Col>
 
  </Row>
</Container>

 )
};



export default EditCreationOuvrage;
