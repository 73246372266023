import React , {useEffect} from "react";
import { Container, Row, Col } from "shards-react";


import PageTitle from "../../components/common/PageTitle"; 
// import UserDetails from "../components/user-profile-lite/UserDetails";
import F_GARBAL from "../GARBAL/F_GARBAL";


const EditMiseEnService = (props) => {

/// const location = useLocation();
  
  useEffect(() => {
  //  console.log( location.info );

 }, []);

 // alert (location.info)

 return (

  <Container fluid className="main-content-container px-4">
  <Row noGutters className="page-header py-4">
    <PageTitle title="Centre d'appel" subtitle="Edition" md="12" className="ml-sm-auto mr-sm-auto" />
  </Row>
  <Row>
    
    <Col lg="12">

      <F_GARBAL Donnees = {"oooooooooo"} />

    </Col>
 
  </Row>
  
</Container>

 )
};



export default EditMiseEnService;
