import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, DatePicker, InputGroupText,
    CardHeader, CardBody, FormSelect,label, InputGroupAddon,
    ButtonToolbar,ButtonGroup,InputGroup,Modal, ModalBody, ModalHeader,
    Button,FormInput } from "shards-react";

// import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";


import { MDBDataTable,
        MDBDataTableV5 ,
       } from 'mdbreact';

// import { MDBDatePickerV5 } from 'mdb-ui-kit';
 import moment from 'moment';


import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import PageTitle from "../../components/common/PageTitle"; 
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params"; 
import { Redirect  } from "react-router-dom";
import { FaSistrix, FaSyncAlt } from "react-icons/fa";


/// import { NavItem, NavLink } from "shards-react";

const  ListGarbal  = (props) => {

  // let history = useHistory();
  // const  Entete =  global.token  
  const LigneGarbal  =   Store2.session("LigneGarbal")
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 


  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const [TestDonnes,setTestDonnes] = useState(0)

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];


const [Open,setOpen] = useState( false )

/////////// FIN EXCEL EXPORT DATA

  const [Content, setContent] = useState()
  // const [datatable, setDatatable] = useState({columns:[], rows:[] })
  //Store2.session("ligne", []); 
  const [datatable, setDatatable] = useState()
  const [MaSelection, setMaSelection] = useState()
  const [Region, setRegion] = useState('')
  const [DateDebut, setDateDebut] = useState()
  const [DateFin, setDateFin] = useState()
  const [Motif, setMotif] = useState('')
  const [MotifAppel,setMotifAppel] = useState('')

  //const LigneClick = "GANSBEOGO"


useEffect(() => {
  loadData();
  MesDonnees( Store2.session("ligneGarbal") )
}, []);



// http://192.168.223.1:4200/api/region/?token=
//	axios.get( Params.Api + '/api/region/?token=' + Token )
  



const loadData = async () => {
  const Token = Store2.session("token" )


  const response = await fetch( Params.Api + '/api/dsh_garbal/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneGarbal", contentData); 

 // MesDonnees(contentData)
}


const MesDonnees =  (data) => {
  setDatatable()
  setDatatable({
    columns: [   
      {
        label: 'Id',
        field: 'idgarbal',
        width: 10,
        sort: 'asc',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'id',
        },
      },
      {
        label: 'date_appel',
        field: 'date_appel',
        width: 100,
      },
      {
        label: 'nom_appelant',
        field: 'nom_appelant',
      // sort: 'asc',
        width: 100,
      },
      {
        label: 'contact_appelant',
        field: 'contact_appelant',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'profil_appelant',
        field: 'profil_appelant',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Region',
        field: 'region',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Province',
        field: 'province',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Commune',
        field: 'commune',
      // sort: 'asc',         
        width: 50,
      },
      {
        label: 'Village',
        field: 'village',
        //sort: 'asc',
        width: 50,
      },
      // {
      //   label: 'observation',
      //   field: 'observation',
      //   //sort: 'asc',
      //   width: 100,
      // },
      {
        label: 'datemaj',
        field: 'datemaj',
        //sort: 'asc',
        width: 50,
      },
  
    ],
    rows: data,
    
  })
}

const loadDataParam = async () => {


  const MesParams = {
    Region :  Region,
    //Province :  Province,
    DateDebut : DateDebut,
    DateFin :  DateFin,
    Motif : MotifAppel,

  }

  //  alert(JSON.stringify( MesParams ))

  const Token = Store2.session("token" )
  const Lien = Params.Api +  "/api/dsh_garbalparams/?token=" + Token

  let MesOptions = { 
    method: 'GET', 
    body: MesParams,
}
/** 
  const response = await fetch(`http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Region=` + Region + `&DateDebut=` + DateDebut + `&DateFin=` + DateFin);
 //  const response = await fetch(URL, MesOptions );


  //alert(`http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Region=` + Region + `&DateDebut=` + DateDebut + `&DateFin=` + DateFin)
  

   const contentData = await response.json();
   alert(contentData)
  setContent( contentData )
  Store2.session("ligneGarbal", contentData); 
  //setDatatable()
  MesDonnees(contentData)

*/

 // alert(`http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Region=` + Region + `&DateDebut=` + DateDebut + `&DateFin=` + DateFin)
//  const DateF  =  moment(DateFin).format("YYYY-MM-DD hh:mm:ss")  

  const DateD  =  moment(DateDebut).format("YYYY-MM-DD")
  const DateF  =  moment(DateFin).format("YYYY-MM-DD")
 const URL = ""

  if(  MotifAppel ==="" ){
    alert( "Selectionnez au moins le motif SVP")
   // URL = `http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF
return
  }
  
try {
  
  const contentData = await axios.get( Params.Api +  `/api/dsh_garbalparams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF)



  setContent( contentData.data )
  Store2.session("ligneGarbal", contentData.data); 
  //setDatatable()
  MesDonnees(contentData.data)


} catch (error) {
  alert("Verifier votre connexion SVP.");
}




}


const Raffraichir = async () => {

  //loadData();

  const Token = Store2.session("token" )
  const response = await fetch( Params.Api +  '/api/dsh_garbal/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneGarbal", contentData); 


  MesDonnees(contentData )

}


const Traite = async () => {

  //loadData();

  const Token = Store2.session("token" )
  const response = await fetch( Params.Api +  '/api/dsh_garbal_traite/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneGarbal", contentData); 


  MesDonnees(contentData )

}



const Supprimer = async () => {


  const Token = Store2.session("token" )

//  Test de confirmation de selection
  if( MaSelection ){
        
          let Reponnse = window.confirm("Êtes-vous sûr de vouloir supprimer cette ligne ?");
// Test de confirmation de suppression
          if (Reponnse ) {
        
                    try {
                      const contentData = await axios.put( Params.Api +  `/api/dsh_garbal_sup/?token=` + Token + `&id=` + checkbox1.idgarbal )
                    
                      const Res = JSON.stringify(contentData.data.Error)

                  //  alert ( Res  )
                    
                      if (Res  === true ){
                        alert("Suppression impossible conctacter Admin")
                        
                      
                      }else{

                        alert("Suppession terminée avec succès")
                      }
                  
                      Raffraichir()
        
                    
                    } catch (error) {
                      alert("Verifier votre connexion SVP.");
                    }
                    
          } else {
            // Do nothing!
        
          }
  
    



    
  }else{
    alert("Selctionner un élément dans la liste SVP")
  }



  



}


const PageSuivante = () => {
  alert ('Redirect')
 return <Redirect to="/Suivi_Classic" />

}

const Modifier = () => {
//	e.preventDefault();
//const  history = useHistory();
// alert( 'ID = ' + MaSelection)

if( MaSelection ){
  Store2.session("GarbalEnCours", true)
  props.history.push("/Edit_GARBAL")
}else{
  Store2.session("GarbalEnCours", false)
  alert("Selctionner un élément dans la liste")
}

Store2.session("PropsPageSuivante", props)
}


const Nouveau = () => {
  //	e.preventDefault();
  //const  history = useHistory();
  // alert( 'ID = ' + MaSelection)
  Store2.session("GarbalEnCours", false)
  Store2.session("LigneGarbal", "")
  props.history.push("/Edit_GARBAL")
  Store2.session("PropsPageSuivante", props.history)

}

const [checkbox1, setCheckbox1] = useState('');
const showLogs2 = (e) => {
  setCheckbox1(e);
  setMaSelection(e.idgarbal)
  Store2.session("LigneGarbal", e)
};

const Lignes =  Store2.session("ligneGarbal")

//alert (JSON.stringify(Lignes))


const ChangeDateDebut = (value) => {
setDateDebut( new Date(value) )
}

const ChangeDateFin  = (value) => {
  setDateFin( new Date(value) )
}

 
                      
const ExportationExcel = () => {

 const Donnees =  Store2.session("ligneGarbal"); 

/**
 
 if modal non activer
 return
else

 */

 if ( MotifAppel ===""){

  return (
    <ExcelFile element={
    <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
    Exporter
    </Button>}>
                <ExcelSheet filename ="ExportGarbal" data={Donnees} name="Employees">
                    <ExcelColumn label="date_appel" value="date_appel"/>
                    <ExcelColumn label="nom_appelant" value="nom_appelant"/>
                    <ExcelColumn label="contact_appelant" value="contact_appelant"/>
                    <ExcelColumn label="profil_appelant" value="profil_appelant"/>
                    <ExcelColumn label="region" value="region"/>
                    <ExcelColumn label="province" value="province"/>
                    <ExcelColumn label="commune" value="commune"/>
                    <ExcelColumn label="village" value="village"/>
                    <ExcelColumn label="observation" value="observation"/>
{/** 
 
 
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>

*/}
                </ExcelSheet>
{/** 
 
                 <ExcelSheet  filename ="ExportGarbal" data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>

*/}
            </ExcelFile>
);

 }else{

  return (

    <ExcelFile element={
    <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
    Exporter
    </Button>}>
                <ExcelSheet filename ="ExportGarbal" data={Donnees} name="Employees">
                    <ExcelColumn label="date_appel" value="date_appel"/>
                    <ExcelColumn label="nom_appelant" value="nom_appelant"/>
                    <ExcelColumn label="contact_appelant" value="contact_appelant"/>
                    <ExcelColumn label="profil_appelant" value="profil_appelant"/>
                    <ExcelColumn label="region" value="region"/>
                    <ExcelColumn label="province" value="province"/>
                    <ExcelColumn label="commune" value="commune"/>
                    <ExcelColumn label="village" value="village"/>
                    <ExcelColumn label="observation" value="observation"/>

                    <ExcelColumn label="motif" value="motif"/>
                    <ExcelColumn label="typeanimaux" value="typeanimaux"/>
                    <ExcelColumn label="nombretete" value="nombretete"/>
                    <ExcelColumn label="quantitebouse" value="quantitebouse"/>
                    <ExcelColumn label="fonctionnement" value="etat"/>
                    <ExcelColumn label="typeaccessoires" value="typeaccessoires"/>
                    <ExcelColumn label="quantite" value="quantite"/>
                    <ExcelColumn label="unite" value="unite"/>
                    <ExcelColumn label="traite" value="traite"/>


                </ExcelSheet>

            </ExcelFile>
);

}



}



const ExportationExcelParams = () => {

  const Donnees =  Store2.session("ligneGarbal"); 
 
 /**
  
  if modal non activer
  return
 else
 
  */
 
   return (
     <ExcelFile element={
     <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
     Exporter
     </Button>}>
                 <ExcelSheet filename ="ExportGarbal" data={Donnees} name="Employees">
                     <ExcelColumn label="date_appel" value="date_appel"/>
                     <ExcelColumn label="nom_appelant" value="nom_appelant"/>
                     <ExcelColumn label="contact_appelant" value="contact_appelant"/>
                     <ExcelColumn label="profil_appelant" value="profil_appelant"/>
                     <ExcelColumn label="region" value="region"/>
                     <ExcelColumn label="province" value="province"/>
                     <ExcelColumn label="commune" value="commune"/>
                     <ExcelColumn label="village" value="village"/>
                     <ExcelColumn label="observation" value="observation"/>

                     <ExcelColumn label="typeanimaux" value="typeanimaux"/>
                     <ExcelColumn label="nombretete" value="nombretete"/>
                     <ExcelColumn label="quantitebouse" value="quantitebouse"/>
                     <ExcelColumn label="fonctionnement" value="etat"/>
                     <ExcelColumn label="typeaccessoires" value="typeaccessoires"/>
                     <ExcelColumn label="quantite" value="quantite"/>
                     <ExcelColumn label="unite" value="unite"/>
                     <ExcelColumn label="traite" value="traite"/>


                 </ExcelSheet>

             </ExcelFile>
 );
 
 }


const MaRegion =   Store2.session("Region")
const Donnees= Store2.session("ligneGarbal")
const MotifGarbal =   Store2.session("MotifGarbal")
// alert ( JSON.stringify( Donnees))



// if (datatable){
// const DonneesCharger = JSON.stringify( Donnees.idgarbal)

// if ( datatable  ){
// if( TestDonnes === 1 ){
    
  if (typeof(datatable) != "undefined"){
   
  if ( Token  === undefined  ||  Token  === "" || TypeConnexion === "Partenaire" ) {
    //Affichage de la redirection
   // alert('  Erreur : Verifier votre Email / Mot de passe. ')
    return <Redirect to='/'/>;
  
  }
  


  const today = new Date();
  const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
  const twoDaysAgo = (d => new Date(d.setDate(d.getDate() - 2)))(new Date());



  return (
    <>
       <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Centre d'appel" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>

            
            <Card small className="mb-4">
            <CardHeader className="border-bottom">
            <CardBody className="p-0 pb-3">


            <Row>   

                                <Col  md="12">

         <ButtonToolbar>

                    <ButtonGroup size="sm">

                    <ExportationExcel />
                    
 {
   /** 
    
                      <Button onClick={() => 
                    ExportModal()
                  // ExportChoix()
                     } marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Export
                      </Button>
                      < ModalContent />
                      
   */
 }
                      
                      
                      
                      <Button onClick={() => Nouveau()} marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Nouveau
                      </Button>


                      
                      
                      <Button onClick={() => Modifier()}
                      marginRight={8}  outline theme="success" className="mb-2 mr-1">
                        En cours
                      </Button>
                      <Button onClick={() => Traite() }
                       marginRight={8} outline theme="primary" className="mb-2 mr-1">
                      Traité
                      </Button>

                      <Button onClick={() => Supprimer()} marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Supprimer
                      </Button>

                      <Button onClick={() => Raffraichir()} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >
                       <FaSyncAlt style={{color: 'blue' , fontSize: '20px' }}/>  
                       
                      </Button>


                      <Button onClick={() => loadDataParam()} marginRight={8} 
                      outline theme="dark" className="mb-2 mr-1" >
                       <FaSistrix style={{color: 'red' , fontSize: '20px' }}/>  
                       
                      </Button>

                      
                     
 
                       {/* <Button onClick={() => PageSuivante() } marginRight={8} 
                      outline theme="dark" className="mb-2 mr-1" >
                        => 
                       
                      </Button>
                       */}



                    </ButtonGroup>

      <InputGroup size="sm" className="ml-auto">

{ /** VOI apres comment gerer les deux InputGroup  */}

       <InputGroup size="sm" className = "d-flex my-auto date-range">




    <InputGroupAddon type="append">

            
          <InputGroupText> 
          <FaSistrix style={{color: 'black' , fontSize: '15px' }}/>  
          </InputGroupText>

        </InputGroupAddon>


        <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
        <DatePicker
          size="sm"
          selected={DateFin}
          onChange={ChangeDateFin}
          placeholderText="Date Fin"
          dropdownMode="select"
          className="text-center"
        />

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>



					  <FormSelect id="MotifAppel"
					  /* required */
					  value= {MotifAppel}
						onChange={(e) => {
						   setMotifAppel(e.target.value)
						  // alert( "valuer = " + e.target.value)
						}}
					  >
							<option> </option>
						{
							MotifGarbal.map( item => {
							return(
							<option value={item.id}>{item.motif} </option>
							)
							})
						}


					  </FormSelect>






    </InputGroup>


      </InputGroup>


    </ButtonToolbar>




     <div>
        <br/>
      </div>

         {/**
          *
          * <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-warning" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>


          */}

          </Col>
                
  

       </Row>




                <MDBDataTableV5  responsive autoWidth striped
                    hover
                    entriesOptions={[10, 20, 30, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    small
                    bordered
                    striped
                   // scrollX
                   //scrollY
                    
                    
                   exportToCSV proSelect 
                    fullPagination 
                    filter='office'  
                    searchTop searchBottom={false}

                    data={datatable}
                    checkbox
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                    showLogs2(e);
                    }}

                />


            </CardBody>
            </CardHeader>
            </Card>

   




 {
     //'oooooooooooooooooooooAAA \n' + JSON.stringify(checkbox1).name
     Store2.session("LigneSelect", checkbox1.idgarbal) 
 }




  </Container>

    </>
  );

}else{

  return(
    <>
           <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Centre d'appel" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>




    <div> CHARGEMENT EN COURS ...  </div>
    </Container>
    
    </>
  )



}

}

export default ListGarbal;