import React, { useState, useEffect } from 'react';
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
   DatePicker,
  Modal,
  FormCheckbox,
} from "shards-react";
 import axios from 'axios';
 import moment from 'moment';
 //import { Redirect  } from "react-router-dom";
 // import { useHistory  } from "react-router-dom";
 import { useHistory } from "react-router-dom";
//import Store2 from "../../views/Storage/Store2"; 

import Store2 from "store2"; 

import Params from "../../Pages/Config/Params"; 

//import { DatePicker } from "baseui/datepicker";
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';
import {StatefulInput} from 'baseui/input';




const F_GARBAL = (props) =>{
//const { history } = props;
// let history = useHistory();
const engine = new Styletron();

const Centered = styled('div', {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
  });

  const Token = Store2.session("token" )
const LigneGarbal  =   Store2.session("LigneGarbal")
// alert('LigneGarbal = ' + JSON.stringify( LigneGarbal ))


const [Checked,setChecked] = useState(false)



 // alert (  JSON.stringify( Date('DD MM YYYY', LigneGarbal.date_appel) )  )
// new Date(""DD-MM-YYYY"", LigneGarbal.date_appel) 
// new Date("2021-11-25") 
// Valeur champs  "2021-11-25T11:08:31.000Z",      Date.parse(LigneGarbal)




///const DateS  =  moment(LigneGarbal.date_appel).format("DD-MM-YYYY hh:mm:ss")
const DateS  =  moment(LigneGarbal.date_appel).format("DD-MM-YYYY")
const NewDate  =   moment( new Date ()).format("DD-MM-YYYY hh:mm:ss")
 //alert ( DateS.toString() )
 // alert( new Date ())
 //alert ( moment( new Date ()).format("DD-MM-YYYY hh:mm:ss") )

 // alert( moment(LigneGarbal.date_appel).toDate() )
new Date()

const [DateSaisie,setDateSaisie] = useState( LigneGarbal ?  new Date (LigneGarbal.date_appel)   : new Date () )
const [NomAppelant,setNomAppelant] = useState( LigneGarbal ? LigneGarbal.nom_appelant : '')
const [Contact,setContact] = useState( LigneGarbal ? LigneGarbal.contact_appelant : '')
const [profil_appelant,setprofil_appelant] = useState(  LigneGarbal ? LigneGarbal.profil_appelant :'')
const [Region,setRegion] = useState(  LigneGarbal ? LigneGarbal.region : '')
const [Province,setProvince] = useState(  LigneGarbal ? LigneGarbal.province : '')
const [Commune,setCommune] = useState(  LigneGarbal ? LigneGarbal.commune : '')
const [Village,setVillage] = useState(  LigneGarbal ? LigneGarbal.village : '')
const [Observation,setObservation] = useState(  LigneGarbal ? LigneGarbal.observation : '')
const [MotifAppel,setMotifAppel] = useState(  LigneGarbal ? LigneGarbal.idmotif : '' )
//alert("Motif= " + MotifAppel )

const [NbTete,setNbTete] = useState(LigneGarbal ? LigneGarbal.nombretete :  0)
const [QteBouse,setQteBouse] = useState(LigneGarbal ? LigneGarbal.quantitebouse :  0)
const [FonctionnementBio,setFonctionnementBio] = useState(LigneGarbal ? LigneGarbal.etat : '')
const [TypeAccesoire,setTypeAccesoire] = useState( LigneGarbal ? LigneGarbal.typeaccessoires : '')
const [TypeAnimaux,setTypeAnimaux] = useState(LigneGarbal ? LigneGarbal.typeanimaux : '')
const [Quantite,setQuantite] = useState(LigneGarbal ? LigneGarbal.quantite :  0)
const [Unite,setUnite] = useState(LigneGarbal ? LigneGarbal.unite : '')

//alert("TypeAccesoire = " + LigneGarbal.typeaccessoires )

//const [QuantiteVente,setQuantiteVente] = useState(0)
//const [QuantiteCompost,setQuantiteCompost] = useState(0)
//const [UniteVente,setUniteVente] = useState('')



const [Traite,setTraite] = useState(  LigneGarbal ? LigneGarbal.traite : false)



useEffect(() => {


  }, []);



const InsertionData = async (Donnees) => {

	// const Token = Store2.session("token" )

	// alert(Params.Api + '/api/dsh_postgarbal/?token=' + Token )

	axios.post( Params.Api + '/api/dsh_postgarbal/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	///alert( JSON.stringify(response.data) )
	//props.history.push("/GARBAL")

	//return <Redirect to="/GARBAL" /> 
	
	// routeChange()
	// window.location.reload(false);
	window.location.href = '/GARBAL' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});



	/** 
		
	const response = await fetch('http://192.168.223.1:4200/api/dsh_postgarbal/?token=' + Token ,{
		Donnees
	});
	 const contentData = await response.json();

	 alert( contentData )
	//Store2.session("ligne", contentData); 
  
   // MesDonnees(contentData)

   */
  }



const UpdateData = async (Donnees) => {

	
	const Token = Store2.session("token" )

///alert (Params.Api +  '/api/dsh_garbal_modif/?token=')
	axios.post( Params.Api +  '/api/dsh_garbal_modif/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	//alert( JSON.stringify(response.data) )
	//props.history.push("/GARBAL")

	//return <Redirect to="/GARBAL" /> 
	
	// routeChange()
	// window.location.reload(false);
	window.location.href = '/GARBAL' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});



	/** 
		
	const response = await fetch('http://192.168.223.1:4200/api/dsh_postgarbal/?token=' + Token ,{
		Donnees
	});
	 const contentData = await response.json();

	 alert( contentData )
	//Store2.session("ligne", contentData); 
  
   // MesDonnees(contentData)

   */


  }
  


const routeChange = () =>{ 
    let path = `newPath`;
   // let history = useHistory();
   // history.push('/GARBAL');
    
  }



const LigneAfficheOpt1 = () =>{

	return(

		<Row form>
				  
	{/* TypeAni */}
	<Col md="4" className="form-group">
	  <label htmlFor="TypeAni">Type animaux</label>
	  <FormSelect id="TypeAni"
		/* required */
		value={TypeAnimaux} 
		onChange={(e) => { 
			setTypeAnimaux(e.target.value)
		}}
		>
			<option> </option>
		<option value="Bovins" >Bovins</option>
		<option value="Porc" >Porc</option>

		</FormSelect>

	</Col>

	{/* NbTete */}   
	<Col md="4" className="form-group">
	  <label htmlFor="NbTete;aux">Nombre de tête</label>
	  <FormInput
		id="NbTete"
		placeholder=""
		type="number"
		value={NbTete}  
		onChange={(e) => {
			setNbTete(e.target.value)
		}}
		/*onChange={() => {}} */

		/>

	</Col>

    {/* Quantité de bouse disponible par jour en Kg  */}    
	<Col md="4" className="form-group">
	  <label htmlFor="QteBouse">Quantité de bouse disponible par jour en Kg</label>
	  <FormInput
		id="QteBouse"
		placeholder=""
		type="decimal"
		value = {QteBouse}
		onChange={(e) => {
			setQteBouse(e.target.value)
		}}
		/*onChange={() => {}} */

		/>

	</Col>


  </Row>
	)

}


const LigneAfficheOpt2 = () =>{

	return(

		<Row form>
				  
	{/* Est-ce que votre biodigesteur fonctionne  */}
	<Col md="3" className="form-group">
	  <label htmlFor="FonctionnementBio">Est-ce que votre biodigesteur fonctionne</label>
	  <FormSelect id="FonctionnementBio"
		/* required */
		value={FonctionnementBio} 
		onChange={(e) => {
			setFonctionnementBio(e.target.value)
		}}
		>
		<option> </option>
		<option>Oui</option>
		<option>Non</option>

		</FormSelect>

	</Col>

  </Row>
	)

}

const LigneAfficheOpt3 = () =>{

	return(

		<Row form>
				  
	{/* Est-ce que votre biodigesteur fonctionne  */}
	<Col md="3" className="form-group">
	  <label htmlFor="FonctionnementBio">Est-ce que votre biodigesteur fonctionne</label>
	  <FormSelect id="FonctionnementBio"
		/* required */
		value={FonctionnementBio} 
		onChange={(e) => {
			setFonctionnementBio(e.target.value)
		}}
		>
		<option> </option>
		<option>Oui</option>
		<option>Non</option>

		</FormSelect>

	</Col>

	{/* Est-ce que votre biodigesteur fonctionne  */}
	<Col md="3" className="form-group">
	  <label htmlFor="TypeAccesoire">Type d’accessoires</label>
	  <FormSelect id="TypeAccesoire"
		/* required */
		value={TypeAccesoire} 
		onChange={(e) => {
			setTypeAccesoire(e.target.value)
		}}
		>
		<option> </option>
		<option>Lampe</option>
		<option>Foyer</option>
		<option>Vannes</option>
		<option>Autre préciser</option>



		</FormSelect>

	</Col>


  </Row>
	)

}

const LigneAfficheOpt4 = () =>{

	return(

		<Row form>
				  
	{/* Est-ce que votre biodigesteur fonctionne  */}
	<Col md="3" className="form-group">
	  <label htmlFor="FonctionnementBio">Est-ce que votre biodigesteur fonctionne</label>
	  <FormSelect id="FonctionnementBio"
		/* required */
		value={FonctionnementBio} 
		onChange={(e) => {
			setFonctionnementBio(e.target.value)
		}}
		>
		<option> </option>
		<option>Oui</option>
		<option>Non</option>

		</FormSelect>

	</Col>

	{/* QuantiteVente */}   
	<Col md="3" className="form-group">
	  <label htmlFor="QuantiteVente">Quantité</label>
	  <FormInput
		id="QuantiteVente"
		placeholder=""
		type="number"
		value={Quantite}  
		onChange={(e) => {
			setQuantite(e.target.value)
		}}
		/*onChange={() => {}} */

		/>

	</Col>

{/* UniteVente  */}
<Col md="3" className="form-group">
	  <label htmlFor="UniteVente">Unité</label>
	  <FormSelect id="UniteVente"
		/* required */
		value={Unite} 
		onChange={(e) => {
			//setUniteVente(e.target.value)
			setUnite(e.target.value)
		}}
		>
		<option> </option>
		<option>Tonne</option>
		<option>Kg</option>
		<option>Charette</option>

		</FormSelect>

	</Col>

  </Row>
	)

}

const LigneAfficheOpt5 = () =>{

	return(

		<Row form>
				  
	{/* Unite  */}
	<Col md="3" className="form-group">
	  <label htmlFor="Unite">Unité</label>
	  <FormSelect id="Unite"
		/* required */
		value={Unite} 
		onChange={(e) => {
			setUnite(e.target.value)
		}}
		>
		<option> </option>
		<option>Tonne</option>
		<option>Kg</option>
		<option>Charette</option>

		</FormSelect>

	</Col>

	{/* QuantiteCompost */}   
	<Col md="3" className="form-group">
	  <label htmlFor="QuantiteCompost">Quantité</label>
	  <FormInput
		id="QuantiteCompost"
		placeholder=""
		type="number"
		value={Quantite}  
		onChange={(e) => {
			setQuantite(e.target.value)
		}}
		/*onChange={() => {}} */

		/>

	</Col>

  </Row>
	)

}


// ENREGISTREMENT DANS LA BASE
const Enregistrer = () =>{

const LigneSelect = Store2.session("LigneSelect") 

	if (
		NomAppelant === "" ||
		NomAppelant === "" ||
		Contact === "" ||
		profil_appelant === "" ||
		Region === "" ||
		Province === "" ||
		Commune === "" ||
		Village === ""  ||
		MotifAppel === "" 
	) {
		alert(" Saisir au moins les champs de base SVP ")
		return;
	}

		const Donnees = {

		IdLigne :  LigneSelect ,
		date_appel : moment(DateSaisie).format("YYYY-MM-DD"),
		nom_appelant : NomAppelant,
		contact_appelant  : Contact,
		profil_appelant : profil_appelant,
		region : Region,
		province : Province,
		commune  : Commune,
		village  : Village,
		observation : Observation ,
		idmotif  :  MotifAppel,
		typeanimaux : TypeAnimaux,
		nombretete  : NbTete,
		quantiteobuse : QteBouse,
		etat  : FonctionnementBio,
		typeaccessoires : TypeAccesoire,
		quantite  : Quantite,
		unite  : Unite,
		traite : Traite ,

		}


	//	alert( moment( Donnees.date_appel).format("DD-MM-YYYY hh:mm:ss") )
// alert( JSON.stringify(Donnees))
const LigneModif = Store2.session("GarbalEnCours")
 // alert("GarbalEnCours = " +  LigneModif)
/// alert("LigneModif = " +  LigneModif)

if (LigneModif === false){
	/// alert("Insert") 
	InsertionData(Donnees)
}else if (LigneModif === true){
	/// alert("Update")
	UpdateData(Donnees)
}

/////  routeChange()
// history.push("/GARBAL")
Store2.session("GarbalEnCours", false)

const PageSuivante = Store2.session("PropsPageSuivante")
//props.history.push("/GARBAL")
///////////  window.location.href = '/GARBAL' 

}


const ChangeDateSaisie = (value) => {
	setDateSaisie( new Date(value) )
}

const ChangeTraite = () => {

	if(Checked){
		setChecked(false)
	}else{
		setChecked(true)
	}

	setTraite(Checked)

}




const MaRegion =   Store2.session("Region")
const MaProvince =   Store2.session("Province")
const MaCommune =   Store2.session("Commune")
const MAVillage =   Store2.session("Village")
const MotifGarbal =   Store2.session("MotifGarbal")

// alert( DateSaisie )


  return (

	  <Card small className="mb-4" >
	  

            { /**
             <Alert theme="success">
                    aaaaaaaaaaaa
                </Alert>

             */}
		
		<ListGroup flush>


		  <ListGroupItem className="p-3">
			<Row>
			  <Col>
				<Form  // onSubmit={this.onEnvoyer}
				>
				  <Row form>
					{/* NomAppelant */}
					<Col md="3" className="form-group">
					  <label htmlFor="NomAppelant">Nom de l'appelant </label>
					  <FormInput
						id="NomAppelant"
						//placeholder="Nom de l'appelant"
						value={NomAppelant} 
						onChange={(e) => {
							setNomAppelant(e.target.value)
						}}
					  />
					</Col>
                    
                    {/* Contact */}
                    <Col md="3" className="form-group">
					  <label htmlFor="Contact">Contact</label>
					  <FormInput
						id="Contact"
						//placeholder="Contact"
						value={Contact} 
						onChange={(e) => {
							setContact(e.target.value)
						}}
					  />
					</Col>

                      {/* ProfilAppelant */}
                    <Col md="3" className="form-group">
					  <label htmlFor="ProfilAppelant">Profil de l'appelant</label>

					<FormSelect id="ProfilAppelant"
					  /* required */
					  value= {profil_appelant}
						onChange={(e) => {
							setprofil_appelant(e.target.value)
						}}
					  >
						<option> </option>
						<option>Opérateur Garbal</option>
						<option>Autres</option>

					  </FormSelect>


					</Col>

					{/* DateSaisie */}
					<Col md="3" className="form-group">
					  <label htmlFor="DateSaisie" >Date</label>
					  <br/>
		



									  <DatePicker
										//  placeholderText="-"
										 // className="form-control"
										//  showDisabledMonthNavigation
										dateFormat="dd-MM-yyyy hh:mm:ss"
										  selected={DateSaisie }
										  //selectsStart  
										 // startDate={DateSaisie }
										 // endDate={this.state.endDate}
										  onChange={date => ChangeDateSaisie( date )}
										 // autosuggest="off"
										 dropdownMode="select"

									  />



{/**
    <StyletronProvider value={engine}>

         
		<DatePicker
		value={DateSaisie}
		onChange={({ date }) =>
		setDateSaisie(Array.isArray(date) ? date : [date])
		}
		/>

 
    </StyletronProvider>
*/}




					</Col>
					
				  </Row>


				  <Row>

					{/* Region */}
					<Col md="3" className="form-group">
					<label htmlFor="Region">Region</label>
			    	<FormSelect id="Region"
						/* required */
						value={Region} 
						onChange={(e) => {
							setRegion(e.target.value)
						}}
						>
							<option> </option>
						{
							MaRegion.map( item => {
							return(
							<option>{item.nom} </option>
							)
							})
						}

						</FormSelect>

					</Col>

					{/* Province */}
					<Col md="3" className="form-group">
					  <label htmlFor="Province">Province</label>
					<FormSelect id="Province"
						/* required */
						value={Province} 
						onChange={(e) => {
							setProvince(e.target.value)
						}}
						>
							<option> </option>
						{
							MaProvince.map( item => {
							return(
							<option>{item.nom} </option>
							)
							})
						}

						</FormSelect>

					</Col>

					{/* commune */}
					<Col md="3" className="form-group">
					<label htmlFor="commune">Commune</label>

					<FormInput
						id="Commune"
						//placeholder="Nom de l'appelant"
						value={Commune} 
						onChange={(e) => {
							setCommune(e.target.value)
						}}
					  />


					</Col>
					
					{/* Village */}
					<Col md="3" className="form-group">
					  <label htmlFor="Village">Village</label>

					  <FormInput
						id="Village"
						//placeholder="Nom de l'appelant"
						value={Village} 
						onChange={(e) => {
							setVillage(e.target.value)
						}}
					  />

					</Col>
				  </Row>


				  <Row form>
					{/* Motif de l'appel */}
					<Col md="4" className="form-group">
					<label htmlFor="MotifAppel">Motif de l'appel</label>
					  <FormSelect id="MotifAppel"
					  /* required */
					  value= {MotifAppel}
						onChange={(e) => {
						   setMotifAppel(e.target.value)
						  // alert( "valuer = " + e.target.value)
						}}
					  >
							<option> </option>
						{
							MotifGarbal.map( item => {
							return(
							<option value={item.id}>{item.motif} </option>
							)
							})
						}


					  </FormSelect>

				

					



					</Col>

					<Col md="4" className="form-group">
				

					</Col>

					</Row>

	 { MotifAppel === "3" ? LigneAfficheOpt1() : null}
	 { MotifAppel === "4" ? LigneAfficheOpt2() : null}
	 { MotifAppel === "5" ? LigneAfficheOpt2() : null}
	 { MotifAppel === "6" ? LigneAfficheOpt2() : null}
	 { MotifAppel === "7" ? LigneAfficheOpt3() : null}
	 { MotifAppel === "8" ? LigneAfficheOpt4() : null}
	 { MotifAppel === "9" ? LigneAfficheOpt5() : null}

	 
	 
	 
	 


				  <Row form>

					{/* Observation */}
					<Col md="12" className="form-group">
					  <label htmlFor="Observation">Observation</label>
					  <FormTextarea id="Observation" rows="5" 
					  value= {Observation}
						onChange={(e) => {
							setObservation(e.target.value)
						}}	
					  />
		
					</Col>

				  </Row>



				  <Row form>

					{/* Traité */}

					<Col md="12" className="form-group">
					  <label htmlFor="Traite">Traité</label>
					  <FormCheckbox
						toggle
						checked={Traite}
						onChange={ () => ChangeTraite() }>
							Traité
					</FormCheckbox>

					</Col>

				  </Row>







				  <Button  onClick={ () => Enregistrer() } 
                                           marginRight={8} theme="success">
                  Enregistrer
                </Button>

							
				<Button onClick={() =>  window.location.href = '/GARBAL'  } marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Annuler
                      </Button>


				</Form>
			  </Col>
			</Row>
		  </ListGroupItem>


		</ListGroup>
	  </Card>

        )
    
}


  
export default F_GARBAL;
