
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Container, FormSelect, Row, Col, Card, CardHeader, CardBody,  } from "shards-react";
  
import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
// import UsersOverview from "./../components/blog/UsersOverview";
// import UsersByDevice from "./../components/blog/UsersByDevice";
// import NewDraft from "./../components/blog/NewDraft";
// import Discussions from "../components/blog/Discussions";
// import TopReferrals from "./../components/common/TopReferrals";
import axios from 'axios'; 
 // import ReactSession from 'react-client-session';
 import { Redirect  } from "react-router-dom";
 //import Store2 from "../views/Storage/Store2"; 
 import Store2 from "store2"; 
 import Params from "../Pages/Config/Params"; 
 import MonChart from "../Pages/Chart/MonChart"; 
//  import Chart_A from "../Pages/Charts/Chart_A"; 
  
import { Circle } from 'uxcore-progress';
/// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Chart from 'react-apexcharts'
import { forEach } from "lodash";
 
import StatusCard from '../Pages/StatusCards/StatusCard'
import statusCards from '../Pages/StatusCards/status-card-data.json'
import { Audio, BallTriangle } from  'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Swal from 'sweetalert2'

const  Accueil = (props)  => {

 ///  const [Ouvrage, seOuvrage] = useState()
 const [MonStatR, setMonStatR] = useState([])
 const [StatVal, setStatVal] = useState([])
 const [RegionVal,setRegionVal] = useState('')
 const [Loading, setLoading] = useState(true)

 const [ListeRegion,setListeRegion] = useState([])

  const Token = Store2.session("token" ) 

  const TypeConnexion = Store2.session("TypeConnexion" ) 


  useEffect(() => {
    /**
         const Recharge = setInterval(() => {  
           alert ("Mon Token = " +  location.state)
   
       }, 3000);
     */

       if ( Token  === undefined  ||  Token  === ""  ) {
         //Affichage de la redirection
        ///// alert(' Erreur : Verifier votre Email / Mot de passe. ')
        // return <Redirect to='/'/>;
         return ;
       }else{
       /// Localisation()
         Recupeartion()
        RecupStat()
        // RecupDataouvrage()
        RecupDataouvrage ("TOUS")
        
       }
      
       
    }, []);

  






    const Messages  =  (Titre, Message, Icone , ButtonText ) => {

      Swal.fire({
        title: Titre,
        text: Message ,
        icon: Icone ,
        confirmButtonText: ButtonText
      })
    }

const smallStats2 = [
  {
    label: "Boucle du Mouhoun",
    value: "2385",
    percentage: "4.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [1, 2, 1, 3, 5, 4, 7]
      }
    ]
  },
  {
    label: "Cascades",
    value: "182",
    percentage: "12.4",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [1, 2, 3, 3, 3, 4, 4]
      }
    ]
  },
  {
    label: "Centre",
    value: "8840",
    percentage: "3.8%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,180,0,0.1)",
        borderColor: "rgb(255,180,0)",
        data: [2, 3, 3, 3, 4, 3, 3]
      }
    ]
  },
  {
    label: "Centre Est",
    value: "29",
    percentage: "2.71%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgb(255,65,105)",
        data: [1, 7, 1, 3, 1, 4, 8]
      }
    ]
  },
  {
    label: "Centre Nord",
    value: "1731",
    percentage: "2.4%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgb(0,123,255,0.1)",
        borderColor: "rgb(0,123,255)",
        data: [3, 2, 3, 2, 4, 5, 4]
      }
    ]
  },
  {
    label: "Centre Ouest",
    value: "3254",
    percentage: "2.4%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgb(0,123,255,0.1)",
        borderColor: "rgb(0,123,255)",
        data: [3, 2, 3, 2, 4, 5, 4]
      }
    ]
  }
  
]

const smallStats3 = [
  {
    label: "Centre Sud",
    value: "120",
    percentage: "4.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [1, 2, 1, 3, 5, 4, 7]
      }
    ]
  },
  {
    label: "Est",
    value: "872",
    percentage: "12.4",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [1, 2, 3, 3, 3, 4, 4]
      }
    ]
  },
  {
    label: "Haut-Bassins",
    value: "1025",
    percentage: "3.8%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,180,0,0.1)",
        borderColor: "rgb(255,180,0)",
        data: [2, 3, 3, 3, 4, 3, 3]
      }
    ]
  },
  {
    label: "Nord",
    value: "5489",
    percentage: "2.71%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgb(255,65,105)",
        data: [1, 7, 1, 3, 1, 4, 8]
      }
    ]
  },
  {
    label: "Plateau Central",
    value: "548",
    percentage: "2.4%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgb(0,123,255,0.1)",
        borderColor: "rgb(0,123,255)",
        data: [3, 2, 3, 2, 4, 5, 4]
      }
    ]
  },
  {
    label: "Sahel",
    value: "1952",
    percentage: "2.4%",
    increase: false,
    decrease: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgb(0,123,255,0.1)",
        borderColor: "rgb(0,123,255)",
        data: [3, 2, 3, 2, 4, 5, 4]
      }
    ]
  }
]



const RecupStat  =  () => {

  // GET STATISTIQUE
	axios.get( Params.Api + '/api/dsh_statistique/?token=' + Token )
  
  .then(function (response) {
  // alert ( "response Sttaistique\n " + response.data)
  // alert ( "response = " + JSON.stringify( response.data ))
  Store2.session("Statistique", JSON.stringify( response.data)  )
  setStatVal( response.data )
  localStorage.setItem('Stat', JSON.stringify(  response.data ) );
 // localStorage.Statistique = JSON.stringify( response.data )  // alert( JSON.stringify(   response.data  ) )

 // alert ( 'NbOuvrage  = ' + JSON.stringify(   response.data[0].nbouvrage)  ) 
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API statistique : constacter l'administrateur \n ` + error.message );
  });
 
   

  
  // GET STATISTIQUE PAR REGION
  // alert( Params.Api + '/api/dsh_statregion/?token=' + Token )
	axios.get( Params.Api + '/api/dsh_statregion/?token=' + Token )
  
  .then(function (response) {
  //  alert ( "response Sttaistique " + JSON.stringify( response ) )
  //alert ( "response = " + JSON.stringify(  response.data )  )
 // Store2.session("StatRegion", JSON.stringify(  response.data ) )
  // Store2.session("StatRegion", JSON.stringify(  response.data ) )

  Store2.session("StatR", JSON.stringify(  response.data ) )
  localStorage.setItem('StatR', JSON.stringify(  response.data ) );
  setMonStatR( JSON.stringify(  response.data) )
  setLoading(false);

 //  alert( JSON.stringify( response.data  ) )

  // alert ( 'NbOuvrage  = ' + JSON.stringify(   response.data[0].nbouvrage)  ) 
  })
  
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API  statistique par region : constacter l'administrateur \n ` + error.message );
  });
 

  

  
  // GET STATISTIQUE PAR PROVINCE
	axios.get( Params.Api + '/api/dsh_statprovince/?token=' + Token )
  
  .then(function (response) {
   // alert ( "response Sttaistique ")
   // alert ( "response = " + response.data[0])
  Store2.session("StatProvince", response.data )
  // alert( JSON.stringify(   response.data  ) )
  // alert ( 'NbOuvrage  = ' + JSON.stringify(   response.data[0].nbouvrage)  ) 
  })
  .catch(function (error) { 
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API  statistique par provnce : constacter l'administrateur \n ` + error.message );
  });



  // GET STATISTIQUE GARBAL PAR REGION
	axios.get( Params.Api + '/api/dsh_statgarbalregion/?token=' + Token )
  
  .then(function (response) {
   // alert ( "response Sttaistique ")
   // alert ( "response = " + response.data[0])
  // Store2.session("StatGarbalRegion", JSON.stringify(  response.data )  )
  localStorage.setItem('StatGarbalRegion', JSON.stringify(  response.data ) );
 //  alert( JSON.stringify(   response.data  ) )
  // alert ( 'NbOuvrage  = ' + JSON.stringify(   response.data[0].nbouvrage)  ) 
  })
  .catch(function (error) { 
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API  statistique par Garbal region : constacter l'administrateur \n ` + error.message );
  });





  
}



const RecupDataouvrage  =  (ValeurRegion) => {

  
//////////  iiiiiccciiiiiiiiiiiiiiiiiiii

  // GET Ouvrage + infos
	axios.get( Params.Api + '/api/ouvrageinfo/?token=' + Token + '&region=' + ValeurRegion )
  .then(function (response) {

  Store2.session("ouvrageinfo", response.data )
  //alert('GET Ouvrage + infos')
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Ouvrage + infos  : constacter l'administrateur \n ` + error.message );
  });



  
  // GET Ouvrage + infos ALL
	axios.get( Params.Api + '/api/ouvrageinfoall/?token=' + Token + '&region=' + ValeurRegion)
  
  .then(function (response) {
//  alert ( JSON.stringify( response ))
  Store2.session("ouvrageinfoall", response.data )
 // alert('GET Ouvrage + infos ALL')

  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

 //  alert(` Erreur API Ouvrage + infos ALL : constacter l'administrateur \n ` + error.message );
  });

  

  // Messages("Selection de la Region", "La region active est " + ValeurRegion , "success", "OK" )
}

const Localisation  =  () => {

// GET REGION
// //alert ('ok')
// fetch( Params.Api + '/api/region/?token=' + Token  )
// .then(response =>{
//   Store2.session("Region", response.json() )
//   alert( JSON.stringify( response.json() ) )
// })
// .then(response => alert(JSON.stringify(response)))
// .catch(error => alert(`Erreur API Region : constacter l'administrateur \n ` + error.message ));



// GET REGION
axios.get( Params.Api + '/api/region/?token=' + Token )
.then(function (response) {

  Store2.session("Region", response.data )
alert( response.data  )
  })
  .catch(function (error) {

  alert(`Erreur API Region : constacter l'administrateur \n ` + error.message );
  });

  



}


 const	Recupeartion  =  () => {

const  Entete =  global.token   
  const Headers = {
    token: Entete
  }; 


// GET REGION
	axios.get( Params.Api + '/api/region/?token=' + Token )
	.then(function (response) {

    Store2.session("Region", response.data )
    setListeRegion( response.data )

	  })
	  .catch(function (error) {
		if (error.response) {

			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);


		}  else if (error.request){

			console.log(error.request);

		}else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		  }

		alert(`Erreur API Region : constacter l'administrateur \n ` + error.message );
	  });

    
    // GET PROVINCE
	axios.get( Params.Api  + '/api/province/?token=' + Token )
  
  .then(function (response) {

  Store2.session("Province", response.data )
  

  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Province : constacter l'administrateur \n ` + error );
  });


      // GET COMMUNE
	axios.get( Params.Api  + '/api/commune/?token=' + Token )
  
  .then(function (response) {

  Store2.session("Commune", response.data )
  
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Commune: constacter l'administrateur \n ` + error.message );
  });


      // GET VILLAGE
	axios.get( Params.Api  + '/api/village/?token=' + Token )
  
  .then(function (response) {

  Store2.session("Village", response.data )
  
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Village : constacter l'administrateur \n ` + error.message );
  });


        // GET localisation
	axios.get( Params.Api  + '/api/localisation/?token=' + Token )
  
  .then(function (response) {

  Store2.session("Localisation", response.data )
  
  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API localisation : constacter l'administrateur \n ` + error.message );
  });




   // GET Motif GARBAl
	axios.get( Params.Api  + '/api/motifgarbal/?token=' + Token )
  
  .then(function (response) {

  Store2.session("MotifGarbal", response.data )
  

  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API Garbal: constacter l'administrateur \n ` + error.message );
  });



  
   // GET Modele BioDigesteur
	axios.get( Params.Api  + '/api/modelBiodigesteur/?token=' + Token )
  
  .then(function (response) {

  Store2.session("ModelBiodigesteur", response.data )
  

  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API ModelBiodigesteur: constacter l'administrateur \n ` + error.message );
  });




    
   // GET last_id_ouvrageden 

	axios.get( Params.Api  + '/api/dsh_last_id_ouvrageden/?token=' + Token )
  
  .then(function (response) {
  // alert( JSON.stringify( response.data ) )
  Store2.session("LastIdOuvrageden", response.data )
  

  })
  .catch(function (error) {
  if (error.response) {

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);


  }  else if (error.request){

    console.log(error.request);

  }else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    }

  alert(`Erreur API LastIdOuvrageden: constacter l'administrateur \n ` + error.message );
  });


  


}

// const {nbouvrage, nbsqv, nbmiseenservice, nbsuivihorssite, nbecb, nbsuiviouvrage, nbgarbal, nbgarbaltraite} = JSON.parse( Store2.session("Statistique") )



 //alert ("Token = " +  Store2.session("token" ))
 // alert ( "MaRedirection = " +  Store2.session("MaRedirection" ) )
/** 
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 
*/
  

 // alert('TypeConnexion =' + TypeConnexion )

if ( Token  === undefined  ||  Token  === ""  ) {
  //Affichage de la redirection
 ///// alert('  Erreur : Verifier votre Email / Mot de passe. ')
  return <Redirect to='/'/>;

}

//    
/** 
    localStorage.user = JSON.stringify({name: "John"});
    localStorage.Nom = 'Dieudonné'

    // sometime later
    let user = JSON.parse( localStorage.user );
    // alert( user.name ); // John
    // alert( localStorage.Nom  )


    localStorage.setItem('Name', 'GANSBEOGO' );
*/


// const Region = Store2.session("Region" )

// const StatRegion1 =  JSON.stringify( Store2.session("StatRegion" ) )

// const StatRegion =  JSON.parse(StatRegion1 )

// const RegStatProvinceion = Store2.session("StatProvince" )



 const EnteteRegion = []
 const DonneesRegion = []
 const DonneesRegion2 = []
// const EnteteProvince = []
// const DonneesProvince = []


//----------------------------------------------------
// Store2.session("Statistique", response.data )
//  const Nb = JSON.parse( localStorage.getItem('Stat') )
////////const Nb = JSON.parse( Store2.session("Statistique") )


  //
  const NbStatR = JSON.parse( localStorage.getItem('StatR') )
  const NbStatRegions = JSON.parse( localStorage.getItem('StatR') )
 // const NbStatR = JSON.parse( Store2.session("StatR")  )

// alert ( NbStatR )

      
  if (Loading) {
   // return <div className="App">Loading...</div>;
  }else{
    NbStatRegions.forEach((item, index) => {
      EnteteRegion.push(item.region);
    // alert( item.nb )
      DonneesRegion.push(item.nb);
    });


  }


// const position = [51.505, -0.09]

const chartOptions = {
  series: [{
      name: 'Online Customers',
      data: [40,70,20,90,36,80,30,91,60]
  }, {
      name: 'Store Customers',
      data: [40, 30, 70, 80, 40, 16, 40, 20, 51, 10]
  }],
  options: {
      color: ['#6ab04c', '#2980b9'],
      chart: {
          background: 'transparent'
      },
      dataLabels: {
          enabled: false
      },
      stroke: {
          curve: 'smooth'
      },
      xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
      },
      legend: {
          position: 'top'
      },
      grid: {
          show: false
      }
  }
}

//  alert( DonneesRegion ) 

const chartOptions2 = {
  series: [ {
      name: 'Données par région',
      data: DonneesRegion ? DonneesRegion : DonneesRegion2          //  DonneesRegion
  }],
  options: {
      color: ['#6ab04c', '#2980b9'],
      chart: {
          background: 'transparent'
      },
      dataLabels: {
          enabled: false
      },
      stroke: {
          curve: 'smooth'
      },
      xaxis: {
          categories: ['Boucle du Mouhoun', 'Cascades', 'Centre', 'Centre Est', 'Centre Nord', 'Centre Ouest', 'Centre Sud', 'Est', 'Haut-Bassins', 'Nord', 'Plateau Central', 'Sahel', 'Sud Ouest']
      },
      legend: {
          position: 'top'
      },
      grid: {
          show: false
      }
  }
}

const chartOuvrageRegion = {
  series: [ {
      name: 'Données par région',
      data: DonneesRegion ? DonneesRegion : DonneesRegion2          //  DonneesRegion
  }],
  options: {
      color: ['#6ab04c', '#2980b9'],
      chart: {
          background: 'transparent'
      },
      dataLabels: {
          enabled: false
      },
      stroke: {
          curve: 'smooth'
      },
      xaxis: {
          categories: EnteteRegion ? EnteteRegion : ['1', '2', '3']
      },
      legend: {
          position: 'top'
      },
      grid: {
          show: false
      }
  }
}

// const ChargementEnCours =  () => (
//     <BallTriangle color="#00BFFF" height={80} width={80} />
//   )



//  ! Store2.session("MotifGarbal") ||
if (  ///  TEST CHARGEMENT
  Store2.session("MotifGarbal") &&
  Store2.session("Village" )  &&
  Store2.session("Commune" )  &&
  Store2.session("Province" )  &&
  Store2.session("Region")

){
 
// alert('  charge ok ')

// alert(  JSON.stringify( StatVal ) )
//  { StatVal ?  ChargementEnCours() : ChargementEnCours() }

if( TypeConnexion === "Opérateur Garbal" ){

  const DonneesGarbalRegion = []
  const DonneesGarbalRegion2 = []
  const EnteteGarbalRegion = []

// alert( localStorage.getItem('StatGarbalRegion')  )


  const NbStatGarbalregion = JSON.parse( localStorage.getItem('StatGarbalRegion') )

  if (typeof(NbStatGarbalregion) !== 'undefined' || NbStatGarbalregion != null) {
    // alert( 'undefined  ou  null')
    NbStatGarbalregion.forEach((item, index) => {
     EnteteGarbalRegion.push(item.region);
    // alert( item.nb )
      DonneesGarbalRegion.push(item.nb);
    });


   }





  const chartOptionsGarbal = {
    series: [ {
        name: 'Données par région',
        data: DonneesGarbalRegion ? DonneesGarbalRegion : DonneesGarbalRegion2          //  DonneesRegion
    }],
    options: {
        color: ['#6ab04c', '#2980b9'],
        chart: {
            background: 'transparent'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'    
        },
        xaxis: {
            categories: EnteteGarbalRegion ? EnteteGarbalRegion : ['1', '2', '3']
        },
        legend: {
            position: 'top'
        },
        grid: {
            show: false
        }
    }
  }

  


return (
 
  <Container fluid className="main-content-container px-4">
  {/* Page Header */}
  <Row noGutters className="page-header py-4">
    <PageTitle title="Accueil" subtitle="Tableau de bord" className="text-sm-left mb-3" />
  </Row>


 

  <Row>


  {/* Nb.nbgarbal */}
  <Col lg="3" md="3" sm="6" className="mb-3">

   <Card >
      <StatusCard
      // icon={item.icon}
       count= { StatVal ?  StatVal.nbgarbal : ''}
        title= "Total Appel garbal"
         />
   </Card>

  </Col>


   {/*Nb.nbgarbaltraite  */}
   <Col lg="3" md="3" sm="6" className="mb-3">

<Card >
   <StatusCard
   // icon={item.icon}
    count= { StatVal ? StatVal.nbgarbaltraite : ''}
     title= "Total Appel traité"
      />
</Card>

</Col>


 {/* nbecb */}
 {/* <Col lg="3" md="3" sm="6" className="mb-3">

<Card >
   <StatusCard
   // icon={item.icon}
    count= { StatVal ? StatVal.nbecb : ''}
     title= "Total Entreprise"
      />
</Card>

</Col> */}


  </Row>





  <Row>

     <Col lg="12" md="12" sm="12" className="mb-4">
  

             <div >
                <div className="card full-height">
                    {/* chart */}
                    <Chart
                    options={ 
                      chartOptionsGarbal.options
                    
                  }
                        series={chartOptionsGarbal.series}
                        //type='line' bar scatter   heatmap
                        type="bar"
                        //height='100%'
                       // width={500}
                        height={420}
                    />
                </div>
            </div>

            
      </Col>
      

</Row>



  <Row>
    {/* Users Overview */}
    <Col lg="8" md="12" sm="12" className="mb-4">
     {/* //////// <UsersOverview />   */}


    </Col>

    {/* Users by Device */}
    <Col lg="4" md="6" sm="12" className="mb-4">
    
        {/* //////// <UsersByDevice />   */}
    </Col>

    {/* New Draft */}
    <Col lg="4" md="6" sm="12" className="mb-4">
     {/**  <NewDraft />  */}
    </Col>

    {/* Discussions */}
    <Col lg="5" md="12" sm="12" className="mb-4">
     {/**  <MonChart /> */}   {/**  Discussions  s */}
    
      
    </Col>

    {/* Top Referrals */}
    <Col lg="3" md="12" sm="12" className="mb-4">
      {/** <TopReferrals />  */}
    </Col>
  </Row>

  
</Container>


)

}else{
 // const {nbouvrage,nbsqv, nbmiseenservice, nbsuivihorssite, nbecb, nbsuiviouvrage, nbgarbal, nbgarbaltraite} =  JSON.parse( Store2.session("Statistique") )


  return (
 


<Container fluid className="main-content-container px-4">
  
{/* Page Header */}
<Row noGutters className="page-header py-4">
  <PageTitle title="Accueil" subtitle="Tableau de bord" className="text-sm-left mb-3" />
</Row>

{/* Small Stats Blocks     <Chart_A /> */}

{/* <div>
    <div style={{ display: 'inline-block' }}>
      <Circle percent={30} status="exception" infoType="percent" />
      
    </div>
    <div style={{ display: 'inline-block' }}>
      <Circle percent={100} status="success" />
    </div>
    <div style={{ display: 'inline-block' }}>
      <Circle percent={50} showInfo />
    </div>
  </div> */}


<Row>
 
 
 {/* Nb.nbgarbal */}
 <Col lg="3" md="3" sm="6" className="mb-3">

 <Card >
     <StatusCard
     // icon={item.icon}
      count= {StatVal? StatVal.nbouvrage : '' }
       title= "Total biodigesteur"
        />
  </Card>

 </Col>



    {/* Nb.nbmiseenservice */}
    <Col lg="3" md="3" sm="6" className="mb-3">

      <Card >
        <StatusCard
        // icon={item.icon}
          count= {StatVal ? StatVal.nbbskpmg1 : ''}
          title= "Total KPMG vérification 1"
            />
      </Card>

      </Col>




        {/* Nb.nbsqv */}
        <Col lg="3" md="3" sm="6" className="mb-3">

          <Card >
            <StatusCard
            // icon={item.icon}
              count= {StatVal ? StatVal.nbbskpmgsuivi : ''}
              title= "Total Suivi"
                />
          </Card>

        </Col>
        




        {/* Nb.nbsuivihorssite */}
        <Col lg="3" md="3" sm="6" className="mb-3">

          <Card >
              <StatusCard
              // icon={item.icon}
              count= { StatVal ? StatVal.nbbskpmg2 : ''}
                title= "Total  KPMG vérification 2"
                />
          </Card>

        </Col>
        


    {/* Total Suivi classique */}
    <Col lg="3" md="3" sm="6" className="mb-3">

    {/* <Card >
      <StatusCard
      // icon={item.icon}
        count= { StatVal ? StatVal.nbsuiviouvrage : ''}
        title= "Total Suivi classique"
          />
    </Card> */}

</Col>



  {/* Nb.nbgarbal */}
  <Col lg="3" md="3" sm="6" className="mb-3">

   {/* <Card >
      <StatusCard
      // icon={item.icon}
       count= { StatVal ?  StatVal.nbgarbal : ''}
        title= "Total Appel garbal"
         />
   </Card> */}

  </Col>


   {/*Nb.nbgarbaltraite  */}
   <Col lg="3" md="3" sm="6" className="mb-3">

    {/* <Card >
      <StatusCard
      // icon={item.icon}
        count= { StatVal ? StatVal.nbgarbaltraite : ''}
        title= "Total Appel traité"
          />
    </Card> */}

   </Col>


 {/* nbecb */}
 {/* <Col lg="3" md="3" sm="6" className="mb-3">

<Card >
   <StatusCard
   // icon={item.icon}
    // count= { StatVal ? StatVal.nbecb : ''}
    count= { '0'}
     title= "Total Ecb"
      />
</Card>

</Col> */}


  </Row>




 {/*  CHARGEMENT DES OUVRAGES */}
<Row>
					{/* Region */}
					<Col md="6" className="form-group">
					<label htmlFor="Region">Region</label>
			    	<FormSelect id="Region"
						/* required */
						value={RegionVal} 
						onChange={(e) => {
							setRegionVal(e.target.value)
              RecupDataouvrage (e.target.value)
              Store2.session("RegionVal", e.target.value )
              Messages("Selection de la Région", "La région active est " + e.target.value , "success", "OK" )

						}}
						>
              <option >TOUTES</option>             
              {
                ListeRegion.map( item => ( 

                      <option  key={item.idregion } > { item.nom} </option>
  
                ))
              }




						</FormSelect>

					</Col>

          <Col md="6" className="form-group">
          <a href="http://biosynchro.org/APK/BioCollectAppNGML.apk" title="Apk BioCollectApp">Télécharger Apk BioCollectApp</a>
          </Col>

          
    {/*Nb.nbouvrage */}
    <Col lg="6" md="6" sm="6" className="mb-3">



</Col>


</Row>





<Row>


  {/* Users by Device */}
  <Col lg="12" md="12" sm="12" className="mb-4">
  
      {/* //////// <UsersByDevice />   */}

      <div >
                    <div className="card full-height">
                        {/* chart */}
                        <Chart
                        options={ 
                          chartOuvrageRegion.options
                        
                      }
                            series={chartOuvrageRegion.series}
                            //type='line' bar scatter   heatmap
                            type="bar"
                            //height='100%'
                           // width={500}
                            height={420}
                        />
                    </div>
                </div>

                
  </Col>

  {/* New Draft */}
  <Col lg="4" md="6" sm="12" className="mb-4">
   {/*******  <NewDraft />  */}


  </Col>

  {/* Discussions */}
  <Col lg="5" md="12" sm="12" className="mb-4">
    { /*********** <Discussions /> */}
  </Col>

  {/* Top Referrals */}
  <Col lg="3" md="12" sm="12" className="mb-4">
    {/*******  <TopReferrals />  */}
  </Col>
</Row>


</Container>
  
  )

}



} /// Fin test CHARGEMENT
else {

return (
  <div className={{
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }}>

<BallTriangle color="#00BFFF" height={80} width={80} />

</div>
)

}



}
export default Accueil;


